import styled from 'styled-components';

export const ProductTileContainer = styled.div`
  width: 20%;
  max-width: 75px;
  height: 100%;
  flex-shrink: 0;
  padding: 0.5rem;
  box-sizing: border-box;
  align-self: flex-start;
  border-radius: 3px;

  ${({ isSelected }) =>
    isSelected
      ? `
        border: 2px solid #37d5d3;`
      : `
        border: 2px solid transparent;`}
`;

export const ProductImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const ProductScore = styled.div`
  background: rgb(235, 163, 44);
  display: inline-block;
  padding: 0.5rem;
  border-radius: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  line-height: 1;
  position: absolute;
  color: white;
  top: 6px;
`;

export const ProductRating = styled.div`
  font-size: 1.4rem;
  display: block;
  text-align: center;
  color: #555555;
`;

export const SelectionTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-top: 0.5rem solid #37d5d3;
  margin: auto;
  position: relative;
  top: 4px;
`;
