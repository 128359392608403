import { config } from '../components/version-config';
export const SET_VERSION_CONFIG = 'SET_VERSION_CONFIG';

export const setVersionConfig = (eid) => {
  const lastVersion = Object.keys(config)[Object.keys(config).length-2];
  return {
    type: SET_VERSION_CONFIG,
    payload: config[eid] ? config[eid] : config[lastVersion],
  }
}
