import './scrollable.css';

export var Scrollable = {
  state: {
    element: null,
    slides: null,
    start: null,
    end: null,
    index: 0,
    transform_position: 0,
    allowTouchSlide: false,
    horizontal: false,
  },

  transformString: function (coord) {
    if (this.state.horizontal) {
      return `translate3d(${coord}px, 0, 0)`;
    } else {
      return `translate3d(0, ${coord}px,0)`;
    }
  },

  getCoord: function (touch) {
    if (this.state.horizontal) {
      return touch.pageX;
    } else {
      return touch.pageY;
    }
  },

  initialize: function (elem) {
    this.state.slides = elem.getElementsByClassName('slide');
    this.state.element = elem;
    this.state.index = 0;

    if (!this.state.horizontal) {
      var content_window = document.getElementById('content_window');
      var slideHeight = content_window.offsetHeight - 120;
      Array.prototype.forEach.call(this.state.slides, function (slide) {
        slide.style.height = slideHeight + 'px';
      });
    }

    var _this = this;
    Array.prototype.forEach.call(this.state.slides, function (slide) {
      slide.addEventListener('touchstart', function (evt) {
        var touches = evt.changedTouches;

        _this.state.start = _this.getCoord(touches[0]);
        _this.state.element.style.transition = '';
        _this.state.element.style.WebkitTransition = '';
        _this.state.element.style.MozTransition = '';
      });
    });

    Array.prototype.forEach.call(this.state.slides, function (slide) {
      slide.addEventListener('touchmove', function (evt) {
        if (!_this.state.allowTouchSlide) {
          return;
        }

        var touches = evt.changedTouches;
        // var y = _this.getCoord(touches[0]);

        var dy =
          _this.getCoord(touches[touches.length - 1]) - _this.state.start;
        dy += _this.state.transform_position;
        var translate = this.transformString(dy);

        _this.state.element.style.transform = translate;
        _this.state.element.style.WebkitTransform = translate;
        _this.state.element.style.MozTransform = translate;
        _this.state.element.style.MsTransform = translate;

        // evt.stopPropagation();
      });
    });

    Array.prototype.forEach.call(this.state.slides, function (slide) {
      slide.addEventListener('touchend', function (evt) {
        var touches = evt.changedTouches;
        var y = _this.getCoord(touches[0]);

        if (!_this.state.allowTouchSlide) {
          _this.reset_position();
          return;
        }

        if (y - _this.state.start < -5) {
          _this.next_slide();
        } else if (y - _this.state.start > 5) {
          _this.prev_slide();
        } else {
          _this.reset_position();
        }
        // console.log('start: ' + _this.state.start + ',' + y);
      });
    });

    return this;
  },
  has_more_slides: function () {
    return this.state.index < this.state.slides.length - 1;
  },

  next_slide: function () {
    // console.log("Scrollable.next_slide");

    if (this.state.index < this.state.slides.length - 1) {
      this.state.index += 1;

      this.state.onswipe &&
        this.state.onswipe(this.state.index - 1, this.state.index);
    }

    this.reset_position();
  },
  prev_slide: function () {
    // console.log("Scrollable.prev_slide");

    if (this.state.index > 0) {
      this.state.index -= 1;
    }

    this.state.onswipe &&
      this.state.onswipe(this.state.index + 1, this.state.index);

    this.reset_position();
  },
  set_slide: function (index) {
    // console.log("Scrollable.set_slide:" + index);

    if (index < 0) {
      index = 0;
    } else if (index >= this.state.slides.length) {
      index = this.state.slides.length - 1;
    }
    if (this.state.index !== index) {
      this.state.index = index;
      this.reset_position();
    }
  },
  get_slide_index: function () {
    return this.state.index;
  },
  get_slide: function (index) {
    return this.state.slides[index];
  },
  reset_position: function () {
    // console.log("Scrollable.Index: " + this.state.index);
    var newY = 0;
    for (var i = 0; i < this.state.index; i++) {
      if (this.state.horizontal) {
        newY -= this.state.slides[i].offsetWidth;
      } else {
        newY -= this.state.slides[i].offsetHeight;
      }
    }

    this.state.transform_position = newY;

    this.state.element.style.transition = '.25s ease-out';
    this.state.element.style.WebkitTransition = '.25s ease-out';
    this.state.element.style.MozTransition = '.25s ease-out';

    var transform_string = this.transformString(newY);

    this.state.element.style.transform = transform_string;
    this.state.element.style.WebkitTransform = transform_string;
    this.state.element.style.MozTransform = transform_string;
    this.state.element.style.MsTransform = transform_string;

    Array.prototype.forEach.call(this.state.slides, function (slide) {
      slide.classList.remove('slide-active');
    });

    this.set_progress();
  },

  set_progress: function () {
    var pct = (100 * Scrollable.state.index) / Scrollable.state.slides.length;
    var indicator = document.getElementById('progress_indicator');
    indicator.style.transition = 'width .3s';
    indicator.style.width = pct + '%';
  },
};
