import styled from 'styled-components';

export const ButtonContainer = styled.div`
  height: 48px;
  width: 48px;
  background: #4eb6c9;
  background-image: url('/static/images/arrow@3x.png');
  background-position: center;
  background-size: 8px 13px;
  background-repeat: no-repeat;
  cursor: pointer;
`;
