import styled from 'styled-components';

export const HorizontalDivider = styled.div`
  width: 1px;
  margin: 0 12px;
  background: #e8e8e8;
`;

export const TwoColumnLayout = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const Column = styled.div`
  flex-basis: 1px;
  flex-grow: 1;
`;

export const IconWrapper = styled.div`
  display: inline-block;
  margin-right: 0.5rem;
`;
