import React from 'react';
import PropTypes from 'prop-types';

import { Scrollable } from '../scrollable';

export class Carousel extends React.Component {
  static propTypes = {
    slide: PropTypes.number,
    onChangeSlide: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.carousel = null;
    this.carouselDOM = null;
    this.setCarouselDOM = element => {
      this.carouselDOM = element;
    };
  }

  componentDidMount() {
    Scrollable.state.horizontal = true;

    this.carousel = Scrollable.initialize(this.carouselDOM);
    if (this.props.slide !== undefined) {
      this.carousel.set_slide(this.props.slide);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.slide !== undefined &&
      this.props.slide !== prevProps.slide
    ) {
      this.carousel.set_slide(this.props.slide);
    }
  }

  changeSlide(index) {
    this.props.onChangeSlide && this.props.onChangeSlide(index);
  }

  render() {
    return (
      <div ref={this.setCarouselDOM}>
        {React.Children.map(this.props.children, function(child) {
          if (React.isValidElement(child)) {
            return child;
          } else {
            return null;
          }
        })}
      </div>
    );
  }
}
