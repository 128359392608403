import React from 'react';
import styled from 'styled-components';

import { PagePadding } from 'shared/styles';
import Logo from 'shared/components/Logo';

export const SiteHeaderContainer = styled.div`
  background: rgb(33 156 177);
  box-shadow: 0px 1px 3px rgb(70 110 116);
`;

const SiteHeader = () => {
  return (
    <SiteHeaderContainer>
      <PagePadding>
        <Logo withName />
      </PagePadding>
    </SiteHeaderContainer>
  );
};

export default SiteHeader;
