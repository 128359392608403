import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../AppContext';
import { AppActions } from '../store';

import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { Tracking } from '../components/tracking';
import { API } from '../components/api';

import GuideRoutes from '../shared/routes/GuideRoutes';

const uppercaseFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const decodeUrl = (str) => str.replace(/-/g, ' ');

const api = new API();

const Guide = ({ match }) => {
  const {
    state: { versionConfig },
    dispatch,
  } = useContext(AppContext);

  const [guideData, setGuideData] = useState(null);

  const {
    params: { guideName, requestedPrimaryAttributeName },
  } = match;

  const guideNameFormatted = decodeUrl(guideName);
  const primaryAttributeNameFormatted = requestedPrimaryAttributeName
    ? decodeUrl(requestedPrimaryAttributeName)
    : null;

  useEffect(() => {
    if (guideNameFormatted && !versionConfig) {
      api.getGuideInfo({
        guideName: guideNameFormatted,
        primaryAttributeName: primaryAttributeNameFormatted,
        onReturn: (data) => {
          window._tracking = Tracking({
            id: data.guide.id,
            name: data.guide.title,
            eid: data.eid,
            chid: data.chid,
            trackingCode: data.tracking_code,
          });
          console.log('eid:' + data.eid);
          dispatch(AppActions.setVersionConfig(data.eid));
          setGuideData(data);
        },
      });
    }
  }, [
    dispatch,
    versionConfig,
    primaryAttributeNameFormatted,
    guideNameFormatted,
  ]);

  if (!versionConfig) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{uppercaseFirstLetter(guideNameFormatted)} - Perspect</title>
      </Helmet>
      <GuideRoutes guideData={guideData} versionConfig={versionConfig} />
    </>
  );
};

export default withRouter(Guide);
