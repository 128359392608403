import React from 'react';
import styled from 'styled-components';

import { PagePadding } from 'shared/styles';
import { PageContainer } from './Homepage.styles.js';

const FooterBackground = styled.div`
  width: 100%;
  background: rgb(33 156 177);
`;

const FooterContainer = styled(PageContainer)`
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FooterLink = styled.a`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: left;
  cursor: pointer;
  color: white;
  text-decoration: none;
`;

const CopyrightLink = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 1px;
  text-align: right;
  color: white;
  text-transform: uppercase;
  @media (max-width: 480px) {
    width: 100%;
    margin-left: 0px;
  }
`;

const Footer = (props) => {
  return (
    <FooterBackground>
      <PagePadding>
        <FooterContainer>
          <FooterLink href='mailto:help@getperspect.com'> Contact</FooterLink>
          <CopyrightLink> © 2023 Perspect Labs, Inc. </CopyrightLink>
        </FooterContainer>
      </PagePadding>
    </FooterBackground>
  );
};

export default Footer;
