import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BaseInputField from './BaseInputField';

const InputField = ({ placeholder, onSubmit, successMessage, failMessage }) => {
  const [enteredValue, setEnteredValue] = useState('');

  const handleSubmit = () => {
    onSubmit(enteredValue);
    setEnteredValue('');
  };

  return (
    <BaseInputField
      placeholder={placeholder}
      onSubmit={handleSubmit}
      onEnter={handleSubmit}
      successMessage={successMessage}
      onChange={(e) => setEnteredValue(e.target.value)}
      failMessage={failMessage}
    />
  );
};

InputField.propTypes = {
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  successMessage: PropTypes.string,
  failMessage: PropTypes.string,
};

export default InputField;
