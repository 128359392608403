import React from 'react';

export class SimpleCountdown extends React.Component {
  constructor(props) {
    super(props);

    this.timer = null;
    this.state = {
      currentValue: props.value,
    };

    this.startCountdown();
  }

  countDown() {
    let newValue = this.state.currentValue - 1;
    this.setState({
      currentValue: newValue,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentValue === this.props.value) {
      clearInterval(this.timer);
      this.timer = null;
      this.props.onCountdownComplete && this.props.onCountdownComplete();
    } else if (!Number.isInteger(prevState.currentValue)) {
      this.setState({ currentValue: this.props.value });
    } else if (this.props.value > this.state.currentValue) {
      this.timer && clearInterval(this.timer);
      this.setState({ currentValue: this.props.value });
    } else {
      this.startCountdown();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  startCountdown() {
    if (this.state.currentValue === this.props.value) {
      return;
    }
    if (this.timer) clearInterval(this.timer);

    this.timer = setInterval(this.countDown.bind(this), 50);
  }

  render() {
    return <>{this.state.currentValue || '-'}</>;
  }
}
