import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { withAnswerService } from '../services/withAnswerService';
import { withProductService } from '../services/withProductService';

import { PageContent } from 'shared/styles';
import DisplayOnceLoaded from 'components/common/DisplayOnceLoaded';

import Logo from 'shared/components/Logo';

import { Container, PagePadding } from 'pages/ProductDetailPage/styles';

const ProductDetailsContainer = ({
  versionConfig,
  guideData,
  goToGuideStart,
  onSelectProduct,
  product,
  products,
  answers,
}) => {
  useEffect(() => {
    product &&
      window._tracking.viewedProductsPage({
        featured_product: product.id,
      });
  }, [product]);

  const handleBuyButton = ({ product, sourceUrl }) => {
    window._tracking.choseProduct(product.id, sourceUrl);
  };

  return (
    <Container>
      <PagePadding blueBackground>
        <Logo withName />
      </PagePadding>

      <PageContent>
        <DisplayOnceLoaded isLoading={!product}>
          <versionConfig.productDetailPage
            product={product}
            guideData={guideData}
            goToGuideStart={goToGuideStart}
            onSelectProduct={onSelectProduct}
            answers={answers}
            versionConfig={versionConfig}
            onBuyButton={handleBuyButton}
          />
        </DisplayOnceLoaded>
      </PageContent>
    </Container>
  );
};

ProductDetailsContainer.propTypes = {
  versionConfig: PropTypes.object.isRequired,
  guideData: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  goToGuideStart: PropTypes.func.isRequired,
  answers: PropTypes.array.isRequired,
};

export default withAnswerService(
  withProductService(withRouter(ProductDetailsContainer))
);
