import React, { useState, useEffect } from 'react';

import { API } from 'components/api';
import { Tracking } from 'components/tracking';

import Footer from './Footer';
import HomepageFilterExamples, { Button } from './HomepageFilterExamples';
import HomepageChart from './HomepageChart';

import Modal from 'shared/components/Modal';

import InputField from 'shared/components/InputField';
import { PagePadding } from 'shared/styles';

import {
  PageContainer,
  HomepageHero,
  HomepageTitle,
  HomepageHeroText,
  Section,
  Spacer,
  Bullets,
  Bullet,
  SectionTitle,
  YellowBackground,
  GrayBackground,
} from './Homepage.styles.js';

const api = new API();

const Homepage = (props) => {
  const [signupSuccessMessage, setSignupSuccessMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const tracking = Tracking();
    tracking.viewedHomepage();
  });

  const submitEmailSignup = (email) => {
    api.postData('/api/signup/', {
      email: email,
    });

    setShowModal(false);
    setSignupSuccessMessage("Thanks for signing up! We'll be in touch soon.");
  };

  if (showModal) {
    return (
      <Modal
        isOpen={true}
        onRequestClose={() => setShowModal(false)}
        style={{
          overlay: {
            display: 'flex',
          },
          content: {
            position: 'static',
            width: '288px',
            height: '200px',
            margin: 'auto',
            inset: 'auto',
          },
        }}
      >
        <div style={{ fontSize: '20px', marginBottom: '48px' }}>
          {' '}
          Let us know how to reach you. We'll be in touch soon!{' '}
        </div>
        <InputField
          placeholder={'Enter your email'}
          onSubmit={submitEmailSignup}
        />
      </Modal>
    );
  }

  return (
    <PageContainer>
      <HomepageHero>
        <PagePadding>
          <HomepageTitle>Your Creative Strategy Catalyst</HomepageTitle>
          <HomepageHeroText>
            Pick any products you want, and let AI find the most compelling and
            copy-worthy elements in their reviews. It's the easy way to
            customer-inspired creative strategy.
          </HomepageHeroText>
          <Spacer />
          <Button onClick={() => setShowModal(true)}> Get Early Access </Button>
          {signupSuccessMessage && (
            <>
              <Spacer />{' '}
              <div
                style={{
                  color: 'white',
                  border: '1px solid white',
                  textAlign: 'center',
                  padding: '20px',
                }}
              >
                {signupSuccessMessage}
              </div>
            </>
          )}
        </PagePadding>
      </HomepageHero>

      <YellowBackground>
        <Section>
          <PagePadding>
            <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
              <div
                style={{
                  width: '500px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <SectionTitle>Build credibility with stakeholders</SectionTitle>
                <Bullets>
                  <Bullet>Educate with deep customer research</Bullet>
                  <Bullet>
                    Uncover unique selling points against competitors
                  </Bullet>
                  <Bullet>Overcome objections with quantitative data</Bullet>
                </Bullets>
              </div>
              <HomepageChart />
            </div>
          </PagePadding>
        </Section>
      </YellowBackground>

      <GrayBackground>
        <Section>
          <PagePadding>
            <SectionTitle>
              Find new angles without the grind & guesswork
            </SectionTitle>
            <HomepageFilterExamples />
          </PagePadding>
        </Section>
      </GrayBackground>

      <YellowBackground>
        <Section>
          <PagePadding>
            <SectionTitle>
              Keep campaigns scaling with fresh variations
            </SectionTitle>
            <div>
              Customers are your brand’s best storytellers. Let them help you
              find new ways to tell the story.
            </div>
            <div
              style={{
                border: '1px solid #bbb',
                borderRadius: '8px',
                padding: '24px',
                marginTop: '24px',
              }}
            >
              <div>
                <div style={{ color: '#aaa' }}>
                  Other ways customers talk about
                </div>
                <u>mental focus</u>
                <ul style={{ marginTop: '20px', fontSize: '16px' }}>
                  <li style={{ marginBottom: '5px' }}>
                    brain fog (34 reviews)
                  </li>
                  <li style={{ marginBottom: '5px' }}>
                    afternoon lull (23 reviews)
                  </li>
                  <li style={{ marginBottom: '5px' }}>
                    grogginess (18 reviews)
                  </li>
                  <li style={{ marginBottom: '5px' }}>
                    better than coffee (4 reviews)
                  </li>
                </ul>
              </div>
            </div>
          </PagePadding>
        </Section>
      </YellowBackground>

      <Footer />
    </PageContainer>
  );
};

export default Homepage;
