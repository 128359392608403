import styled, { createGlobalStyle, keyframes } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
      font-family: 'Graphik Web', 'San Francisco', sans-serif;
      font-weight: 400;
      font-style:   normal;
      font-stretch: normal;
      font-feature-settings: "liga", "kern";
      box-sizing: border-box;

  }
`;

export const H1 = styled.div`
  color: #0f1617;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.2px;
  margin-bottom: 16px;
`;

export const Link = styled.div`
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */

  text-align: center;
  letter-spacing: -0.2px;

  color: #4eb6c9;
  cursor: pointer;
`;

export const Columns = styled.div`
  display: flex;
`;

export const PageContent = styled.div`
  padding-top: 40px;
  @media (max-width: 480px) {
    padding-top: 24px;
  }
`;

export const PagePadding = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: 960px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const PageTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 110%;
  letter-spacing: -0.03em;
  color: #0f1617;
  margin-bottom: 24px;

  @media (max-width: 480px) {
    font-size: 32px;
    margin-bottom: 16px;
  }
`;

const fadeIn = keyframes`
  0% {opacity:0;}
  100% {opacity:1;}
`;

export const FadeIn = styled.div`
  animation: ${fadeIn} 2.25s ease-out;
`;
