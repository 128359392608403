import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';

import BaseInputField from 'shared/components/BaseInputField';
import theme from './SearchBar.module.css';

const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

class SearchBar extends Component {
  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex }
  ) => {
    this.props.onSuggestionSelected(event, {
      suggestion,
      suggestionValue,
      suggestionIndex,
    });
  };

  render() {
    let mergedTheme = theme;
    if (this.props.theme) {
      mergedTheme = Object.assign(mergedTheme, this.props.theme);
    }

    const inputProps = {
      placeholder: this.props.placeholderText,
      value: this.props.value,
      onChange: this.props.onChange,
      onBlur: this.props.onBlur,
      onSubmit: this.props.onSubmit,
    };
    return (
      <Autosuggest
        suggestions={this.props.suggestions}
        onSuggestionsFetchRequested={this.props.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.props.onSuggestionsClearRequested}
        getSuggestionValue={this.props.getSuggestionValue}
        renderSuggestion={this.props.renderSuggestion || renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.onSuggestionSelected}
        theme={mergedTheme}
        alwaysRenderSuggestions={true}
        focusInputOnSuggestionClick={false}
        renderInputComponent={(inputProps) => (
          <BaseInputField
            noBottomRadius={this.props.suggestions.length > 0}
            {...inputProps}
          />
        )}
      />
    );
  }
}

SearchBar.propTypes = {
  suggestions: PropTypes.array,
  placeholderText: PropTypes.string,

  value: PropTypes.string.isRequired,
  onSuggestionsFetchRequested: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchBar;
