import React from 'react';
import PropTypes from 'prop-types';

import { PagePadding } from 'shared/styles';
import { GuideIntroText, EmphasizedText } from './GuideIntro.styles';

const GuideIntro = ({ guideName, guideReviews }) => {
  return (
    <PagePadding>
      <GuideIntroText>
        Answer a few questions to see which{' '}
        <EmphasizedText>{guideName.toLowerCase()}</EmphasizedText> match your
        needs based on{' '}
        <EmphasizedText>
          {guideReviews.toLocaleString()} reviews{' '}
        </EmphasizedText>
      </GuideIntroText>
    </PagePadding>
  );
};

GuideIntro.propTypes = {
  guideName: PropTypes.string.isRequired,
  guideReviews: PropTypes.number.isRequired,
};

export default GuideIntro;
