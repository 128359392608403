import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ProductSummary from './ProductSummary';
import { PagePadding } from 'shared/styles';
import {
  AttributeTag,
  ResultsPageHeader,
  ResultsPageTitle,
  ResultsPageSubtitle,
  ResultsHeaderAttributeContainer,
  ResultsCount,
  ProductResultContainer,
  ProductListContainer,
} from './ProductResultsPageV75.styles.js';

import ProductTile from './ProductTile';
import ProductRankingRowLayout from './ProductRankingRowLayout';

const ProductResultsPage = ({
  products,
  matchedReviewCount,
  primaryAttributes,
  selectedAttributes,
  onSelectProduct,
  guideData,
}) => {
  // TODO: Is there a better place for this filtering to happen?
  let maxScore = Math.max.apply(
    null,
    products.map((p) => p.matched)
  );
  let matchedProducts = products.filter((p) => p.matched === maxScore);

  useEffect(() => {
    window._tracking.displayedProducts(matchedProducts.map((p) => p.id));
  });

  const [selectedProduct, setSelectedProduct] = useState(matchedProducts[0]);
  const selectedAttributesNoPrimary = selectedAttributes.filter(
    (a) => !primaryAttributes.includes(a)
  );

  return (
    <>
      <PagePadding>
        <ResultsPageHeader>
          <ResultsPageTitle> {guideData.guide.title} Matched </ResultsPageTitle>

          <ResultsPageSubtitle>
            <div style={{ fontSize: '1.6rem', color: '#555' }}>
              Found <b>{matchedProducts.length} products </b> that rate well for
              your selections
            </div>
            <ResultsHeaderAttributeContainer>
              <AttributeTag>{primaryAttributes.join(' & ')}</AttributeTag>

              {selectedAttributesNoPrimary.map((a) => (
                <AttributeTag key={a}>{a}</AttributeTag>
              ))}
            </ResultsHeaderAttributeContainer>
          </ResultsPageSubtitle>
          <ResultsCount>
            Average rating for {primaryAttributes.join(' & ')}
          </ResultsCount>
        </ResultsPageHeader>

        <ProductRankingRowLayout
          products={matchedProducts}
          numProducts={8}
          selectProduct={(p) => {
            window._tracking.viewedProductsPage({
              featured_product: p.id,
            });
            setSelectedProduct(p);
          }}
          selectedProduct={selectedProduct}
          productRenderComponent={ProductTile}
        />

        <ProductListContainer>
          <ProductResultContainer key={selectedProduct.id}>
            <ProductSummary
              product={selectedProduct}
              primaryAttributes={primaryAttributes}
              selectedAttributes={selectedAttributesNoPrimary}
              onSelectProduct={() =>
                window._tracking.choseProduct(
                  selectedProduct.id,
                  selectedProduct.url
                )
              }
            />
          </ProductResultContainer>
        </ProductListContainer>
      </PagePadding>
    </>
  );
};

ProductResultsPage.propTypes = {
  products: PropTypes.array.isRequired,
  attributeCount: PropTypes.number.isRequired,
};

export default ProductResultsPage;
