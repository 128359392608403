import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { API } from 'components/api';

import QuestionFrame from 'components/questions/QuestionFrameV70';
import PrimaryButton from 'shared/components/PrimaryButton';
import ProductTile from '../ProductResultsPageV76/ProductTile';
import ProductRankingRowLayout from '../ProductResultsPageV76/ProductRankingRowLayout';
import ProductSumary from '../ProductResultsPageV76/ProductSummary';

import {
  GuideIntroTitle,
  GuideIntroSectionTitle,
  GuideIntroText,
  ButtonContainer,
  HowItWorksSection,
  HowItWorksSectionHeader,
  HowItWorksSectionText,
  Section,
  ProductBox,
} from './LandingPage.styles';

const api = new API();

const LandingPage = ({ guide, products, onContinue }) => {
  const [selectedProduct, setSelectedProduct] = useState(products[0]);
  const [productDetails, setProductDetails] = useState(null);

  const getProductDetails = (product) => {
    if (!productDetails) return null;

    const matchedProduct = productDetails.filter((p) => p.id === product.id);

    if (matchedProduct.length === 0) {
      return null;
    } else {
      return matchedProduct[0];
    }
  };

  useEffect(() => {
    api.getProductResults(
      guide.id,
      null, //Number(productId),
      [],
      null,
      null,
      null,
      null,
      (data) => {
        setProductDetails(data.products);
      }
    );
  }, [selectedProduct, guide]);

  const content = (
    <>
      <Section>
        <GuideIntroTitle>
          Shortlist the best {guide.title.toLowerCase()} for your needs
        </GuideIntroTitle>
        <GuideIntroText style={{ marginBottom: '4rem' }}>
          Tell us about yourself, and see which {guide.title.toLowerCase()}{' '}
          lived up to the hype according to the people most like you in
          {' ' + guide.reviews.toLocaleString()} reviews.
        </GuideIntroText>
        <ButtonContainer>
          <PrimaryButton orange onClick={() => onContinue('top')}>
            Get Started
          </PrimaryButton>
        </ButtonContainer>
      </Section>

      <Section border>
        <GuideIntroSectionTitle>How it Works</GuideIntroSectionTitle>
        <GuideIntroText>
          <HowItWorksSection>
            <HowItWorksSectionHeader> We Analyze. </HowItWorksSectionHeader>
            <HowItWorksSectionText>
              We gather customer reviews and identify the needs and concerns
              mentioned within each one.
            </HowItWorksSectionText>
          </HowItWorksSection>
          <HowItWorksSection>
            <HowItWorksSectionHeader> Then Summarize. </HowItWorksSectionHeader>
            <HowItWorksSectionText>
              For each product, those mentions are aggregated to create a more
              objective and trustworthy list of pros and cons.
            </HowItWorksSectionText>
          </HowItWorksSection>
          <HowItWorksSection>
            <HowItWorksSectionHeader>
              Finally Personalize.
            </HowItWorksSectionHeader>
            <HowItWorksSectionText>
              As you answer questions, we remove products that perform poorly
              for the things you need.
            </HowItWorksSectionText>
          </HowItWorksSection>
        </GuideIntroText>
      </Section>

      <Section centered topBorder>
        <GuideIntroSectionTitle>A Few Examples</GuideIntroSectionTitle>
        <ProductRankingRowLayout
          products={products}
          numProducts={4}
          selectProduct={(p) => {
            setSelectedProduct(p);
            window._tracking.tapLandingPageExampleProduct(p.id);
          }}
          selectedProduct={selectedProduct}
          productRenderComponent={ProductTile}
          hideScore={true}
          centered
        />
        {selectedProduct && productDetails && (
          <ProductBox>
            <ProductSumary
              product={getProductDetails(selectedProduct)}
              primaryAttributes={[]}
              hideReadMore
            />
          </ProductBox>
        )}
      </Section>

      <Section>
        <GuideIntroSectionTitle>
          Ready to see your recommendations?
        </GuideIntroSectionTitle>
        <ButtonContainer>
          <PrimaryButton orange onClick={() => onContinue('bottom')}>
            Get Started
          </PrimaryButton>
        </ButtonContainer>
      </Section>
    </>
  );

  return <QuestionFrame question={content} />;
};

LandingPage.propTypes = {
  guide: PropTypes.object.isRequired,
};

export default LandingPage;
