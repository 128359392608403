import styled from 'styled-components';

export const ResultsPageHeader = styled.div`
  line-height: 30px;
  /* identical to box height, or 125% */

  letter-spacing: -0.48px;
  color: #0f1617;
  margin-left: 4px;
`;

export const ResultsPageTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
`;

export const ResultsPageSubtitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
  margin-top: 12px;
  padding: 12px;
  background: #f2f2f2;
  color: #0f1617;
`;

export const ResultsCount = styled.div`
  font-size: 12px;
  color: #999;
  margin-left: 12px;
  margin-top: 16px;
`;

export const Divider = styled.div`
  border-bottom: 1px solid #e8e8e8;
  margin: 16px 0px;
`;

export const ProductResultContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 24px -4px rgba(143, 143, 143, 0.5);
  border-radius: 8px;
  width: 40%;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 16px;
  margin: 8px;
  align-self: flex-start;
  cursor: pointer;

  @media (max-width: 580px) {
    width: 100%;
  }
`;

export const ResultsHeaderAttributeContainer = styled.div`
  margin-top: 12px;
`;

export const Attribute = styled.div`
  display: inline-block;
  padding: 4px;
  text-transform: uppercase;
  border-radius: 6px;
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 10px;
  color: #0f1617;
  border: 1px solid #baaea852;

  ${({ inverted }) =>
    inverted &&
    `
    color: white;
    border: 1px solid #0f1617;
    background: #555555;
    `}
`;

export const ProductListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
`;

export const ProductSummaryHeaderText = styled.div`
  line-height: 16px;
  -webkit-letter-spacing: -0.2px;
  -moz-letter-spacing: -0.2px;
  -ms-letter-spacing: -0.2px;
  letter-spacing: -0.2px;
  margin-bottom: ${(props) => (props.smallMargin ? '4px' : '16px')};
  color: #667074;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`;
