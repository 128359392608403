import React from 'react';
import styled from 'styled-components';

const LogoContainer = styled.div`
  padding-top: 18px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 480px) {
    padding-top: 12px;
    padding-bottom: 10px;
  }
`;

const LogoLink = styled.a`
  background: rgb(177 237 248);
  border-radius: 12px;
  line-height: 0px;
  padding: 4px 8px;
`;

const LogoImage = styled.img`
  height: 44px;
  width: 32px;
  @media (max-width: 480px) {
    height: 32px;
    width: 23px;
  }
`;

const SiteName = styled.div`
  font-family: Georgia;
  font-size: 20px;
  color: rgb(177 237 248);
  line-height: 23px;
  float: right;

  font-weight: normal;
  text-align: right;
  letter-spacing: -0.5px;
`;

const Logo = ({ withName }) => {
  return (
    <LogoContainer>
      <LogoLink href='/'>
        <LogoImage src='/static/images/logo-perspect@3x.png' alt='logo' />
      </LogoLink>
      {withName && <SiteName> Perspect </SiteName>}
    </LogoContainer>
  );
};

export default Logo;
