import React from 'react';
import PropTypes from 'prop-types';

import {
  MainQuestionText,
  SubQuestionText,
  InputFieldContainer,
  CancelText,
} from './styles';
import QuestionFrame from './QuestionFrameV70';
import InputField from 'shared/components/InputField';

const UserInputQuestion = ({
  questionText,
  questionSubtext,
  questionAnswered,
  inputPrompt,
  cancelText,
}) => {
  const question = (
    <>
      <MainQuestionText>{questionText}</MainQuestionText>
      <SubQuestionText>{questionSubtext}</SubQuestionText>
    </>
  );

  const responseComponent = (
    <>
      <InputFieldContainer>
        <InputField placeholder={inputPrompt} onSubmit={questionAnswered} />
      </InputFieldContainer>

      {cancelText && (
        <CancelText onClick={() => questionAnswered(null)}>
          {cancelText}
        </CancelText>
      )}
    </>
  );

  return <QuestionFrame question={question} buttons={responseComponent} />;
};

UserInputQuestion.propTypes = {
  questionText: PropTypes.string.isRequired,
  questionSubtext: PropTypes.string,
  questionAnswered: PropTypes.func.isRequired,
  inputPrompt: PropTypes.string.isRequired,
  cancelText: PropTypes.string,
};

export default UserInputQuestion;
