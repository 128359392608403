import React from 'react';
import PropTypes from 'prop-types';

import { BuyButtonStyle, BuyButtonSpacer, BuyPrice } from './styles';

const BuyButton = ({ price, onClick, text }) => {
  return (
    <BuyButtonStyle onClick={onClick}>
      <BuyPrice>{price}</BuyPrice> <BuyButtonSpacer>|</BuyButtonSpacer>
      {text || 'Buy'}
    </BuyButtonStyle>
  );
};

BuyButton.propTypes = {
  price: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default BuyButton;
