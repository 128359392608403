import React from 'react';
import PropTypes from 'prop-types';
import Card from '../common/Card';
import { SimpleCountdown } from '../SimpleCountdown';
import ProductRankingAnimation from './ProductRankingAnimation';
import {
  ProductRankingItem,
  Badge,
  ProductRankingPreviewContainer,
  ProductRankingMatches,
  ProductRankingMatchText,
  CenteredBox,
  BottomOverlay,
  ImageContainer,
  ProductImg,
  SortIndex,
} from './styles';

const ProductRankingPreviewV70 = ({
  products,
  productCount,
  matchedCount,
  matchedText,
}) => {
  return (
    <BottomOverlay>
      <CenteredBox>
        <ProductRankingMatches>
          {productCount && (
            <>
              <SimpleCountdown value={matchedCount || productCount} />
              <ProductRankingMatchText>
                {' ' + matchedText}
              </ProductRankingMatchText>
            </>
          )}
        </ProductRankingMatches>
        <ProductRankingPreviewContainer>
          {products.length > 0 && (
            <ProductRankingAnimation>
              {products.map((product) => (
                <ProductRankingItem
                  key={product.title}
                  className='productRankingItem'
                >
                  <Badge>{product.matched}</Badge>
                  <Card borderRadius={8}>
                    <ImageContainer>
                      <ProductImg src={product.image_url} alt={product.title} />
                    </ImageContainer>
                  </Card>
                  <SortIndex className='prtIndex'>{product.matched}</SortIndex>
                </ProductRankingItem>
              ))}
            </ProductRankingAnimation>
          )}
        </ProductRankingPreviewContainer>
      </CenteredBox>
    </BottomOverlay>
  );
};

ProductRankingPreviewV70.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      matched: PropTypes.number.isRequired,
      image_url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  productCount: PropTypes.number.isRequired,
  matchedCount: PropTypes.number.isRequired,
  matchedText: PropTypes.string.isRequired,
};

export default ProductRankingPreviewV70;
