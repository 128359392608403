import React from 'react';

import classes from './Loader.module.css';

export const Loader = ({ isVisible, text, scale }) => {
  if (!isVisible) {
    return null;
  }

  const scaleStyle = scale ? { transform: `scale(${scale})` } : {};
  return (
    <div className='loader_container'>
      {text && <div className='loader_text'> {text} </div>}
      <div style={scaleStyle} className={classes.ldsRoller}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
