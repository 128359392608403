import React from 'react';
import PropTypes from 'prop-types';

import RightArrowButton from 'shared/components/RightArrowButton';
import {
  InputFieldContainer,
  InputFieldElement,
  Success,
  Fail,
} from './InputField.styles.js';

const BaseInputField = ({
  placeholder,
  value,
  onChange,
  onBlur,
  onSubmit,
  onEnter,
  successMessage,
  failMessage,
  noBottomRadius,
}) => {
  const handleKeyPress = (event) =>
    event.key === 'Enter' && onEnter && onEnter();

  return (
    <>
      <InputFieldContainer noBottomRadius={noBottomRadius}>
        <InputFieldElement
          placeholder={placeholder}
          type='text'
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={handleKeyPress}
          noBottomRadius={noBottomRadius}
          onSubmit={() => onSubmit(value)}
        />
        <RightArrowButton
          onClick={() => {
            onSubmit(value);
          }}
        />
      </InputFieldContainer>
      {successMessage && <Success> {successMessage}</Success>}
      {failMessage && <Fail> {failMessage}</Fail>}
    </>
  );
};

BaseInputField.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  successMessage: PropTypes.string,
  failMessage: PropTypes.string,
  noBottomRadius: PropTypes.bool,
};

export default BaseInputField;
