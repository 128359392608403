import React from 'react';
import PropTypes from 'prop-types';

import { PagePadding } from 'shared/styles';

import PrimaryButton from 'shared/components/PrimaryButton';

import {
  LandingPageContainer,
  LandingPageContentContainer,
  GuideIntroTitle,
  GuideIntroText,
  KeyStatsContainer,
  ButtonContainer,
  SectionContent,
} from './LandingPage.styles';

const KeyStat = ({ number, text }) => {
  return (
    <div style={{ textAlign: 'center', lineHeight: '1.4' }}>
      <div style={{ color: 'rgb(235 163 44)', fontSize: '2.4rem' }}>
        {number}
      </div>
      <div style={{ fontSize: '1.2rem', color: '#888' }}> {text} </div>
    </div>
  );
};

const LandingPage = ({
  guide,
  questions,
  products,
  productCount,
  onContinue,
}) => {
  return (
    <LandingPageContainer>
      <PagePadding>
        <LandingPageContentContainer>
          <SectionContent>
            <GuideIntroTitle>
              Shortlist the best {guide.title.toLowerCase()} for your needs
            </GuideIntroTitle>
            <GuideIntroText>
              Tell us about yourself, and see which {guide.title.toLowerCase()}{' '}
              lived up to the hype according to the people most like you.
            </GuideIntroText>
            <GuideIntroText>
              As you answer, we'll eliminate products that performed poorly
              based on {guide.reviews.toLocaleString()} reviews.
            </GuideIntroText>
          </SectionContent>
          <SectionContent>
            <KeyStatsContainer>
              <KeyStat number={productCount} text={guide.title.toLowerCase()} />
              <KeyStat
                number={guide.reviews.toLocaleString()}
                text={'reviews'}
              />
              <KeyStat
                number={
                  questions.filter(
                    (q) => !q.shownIf || q.questionType === 'AttributeQuestion'
                  ).length +
                  '-' +
                  questions.length
                }
                text={'questions'}
              />
            </KeyStatsContainer>
          </SectionContent>
          <SectionContent>
            <ButtonContainer>
              <PrimaryButton
                orange
                style={{ boxShadow: '1px 2px 8px #555' }}
                onClick={() => onContinue()}
              >
                Get Started ➔
              </PrimaryButton>
            </ButtonContainer>
          </SectionContent>
        </LandingPageContentContainer>
      </PagePadding>
    </LandingPageContainer>
  );
};

LandingPage.propTypes = {
  guide: PropTypes.object.isRequired,
};

export default LandingPage;
