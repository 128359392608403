import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

export const ProductRatingContainer = styled.div`
  display: flex;
  line-height: 1.4;

  ${({ inline }) =>
    !inline &&
    `
      align-items: center;
      flex-direction: column;
   `}
`;

const ReviewText = styled.div`
  color: #999;
  ${({ inline }) => inline && `display: inline;`}
`;

export const Star = () => {
  let starStyle = {
    color: 'gold',
    width: '1em',
    display: 'inline-block',
  };

  return <div style={starStyle}>★</div>;
};

const StarRatingValue = styled.div`
  display: flex;
  align-self: flex-start;
`;

const StarRatingText = styled.div`
  ${({ lightened }) => lightened && `color: #999;`}
  margin-left: 2px;
`;

const StarViz = ({
  starRating,
  numberReviews,
  shortened,
  inline,
  lightened,
  style,
}) => {
  let reviewText = numberReviews ? ` (${numberReviews.toLocaleString()}` : '';
  if (reviewText !== '') {
    if (shortened) {
      reviewText = reviewText + ')';
    } else {
      reviewText = reviewText + ' reviews)';
    }
  }

  return (
    <ProductRatingContainer inline={inline}>
      <StarRatingValue>
        <Star />
        <StarRatingText lightened={lightened}>{starRating}</StarRatingText>
      </StarRatingValue>
      <StarRatingText lightened={true}>
        <ReviewText inline={inline}>{reviewText}</ReviewText>
      </StarRatingText>
    </ProductRatingContainer>
  );
};

StarViz.propTypes = {
  starRating: PropTypes.number.isRequired,
  numberReviews: PropTypes.number,
  shortened: PropTypes.bool,
};

export default StarViz;
