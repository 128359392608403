import React, { useReducer } from 'react';
import GuideContext from './GuideContext';
import { guideReducer, guideInitialState } from './store/reducer';

const GuideProvider = ({ children }) => {
  const [state, dispatch] = useReducer(guideReducer, guideInitialState);
  return (
    <GuideContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      {children}
    </GuideContext.Provider>
  );
};

export default GuideProvider;
