import styled from 'styled-components';

const Card = styled.div`
  box-shadow: 0px 0px 16px -4px rgba(87, 87, 87, 0.5);
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius + 'px' : '4px'};
  padding: ${(props) => props.p ? props.p + 'px' : '8px'};
`;

export default Card;
