import React from 'react';
import PropTypes from 'prop-types';
import StarRating from 'shared/components/StarRating';

import {
  ProductRatingContainer,
  ImageContainerFullSize,
  ImageContainer,
  ProductImg,
  ProductBrand,
  ProductTitle,
  ProductRating,
  ProductRatingData,
  ProductPrice,
  ProductInformationAdditonalContainer,
} from './ProductInformation.styles';

const ProductInformation = ({
  product,
  withRating,
  withPrice,
  additional,
  onClick,
  fullSize,
}) => {
  const ImageSize = fullSize ? ImageContainerFullSize : ImageContainer;

  return (
    <ProductRatingContainer onClick={onClick} vertical={fullSize}>
      <ImageSize>
        <ProductImg alt={product.title} src={product.image_url} />
      </ImageSize>
      <div>
        <ProductBrand>{product.brand}</ProductBrand>
        <ProductTitle>{product.title}</ProductTitle>
        {withRating && product.average_rating && (
          <ProductRating>
            <StarRating rating={product.average_rating} />
            <ProductRatingData>
              {product.average_rating} (
              {product.number_of_reviews.toLocaleString()} reviews)
            </ProductRatingData>
          </ProductRating>
        )}
        {withPrice && product.formatted_price && (
          <ProductPrice> {product.formatted_price}</ProductPrice>
        )}
        <ProductInformationAdditonalContainer>
          {additional}
        </ProductInformationAdditonalContainer>
      </div>
    </ProductRatingContainer>
  );
};

ProductInformation.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    average_rating: PropTypes.number.isRequired,
    number_of_reviews: PropTypes.number.isRequired,
  }).isRequired,
  withRating: PropTypes.bool,
  withPrice: PropTypes.bool,
};

export default ProductInformation;
