import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export const QUESTION_TYPE = {
  PriceQuestion: 'PriceQuestion',
  PriceQuestionFollowup: 'PriceQuestionFollowup',
  AttributeQuestionFull: 'AttributeQuestionFull',
  AttributeQuestion: 'AttributeQuestion',
  TextQuestion: 'TextQuestion',
  YesNoQuestion: 'YesNoQuestion',
  ConfirmationQuestion: 'ConfirmationQuestion',
  ConfirmGuideQuestion: 'ConfirmGuideQuestion',
  UserInputQuestion: 'UserInputQuestion',
  AttributeChoiceQuestion: 'AttributeChoiceQuestion',
};

export const QuestionManager = ({
  answers,
  questions,
  prices,
  guide,
  questionComponents,
  handleAnswered,
  updateMatchCount,
  navigateToProductResults,
}) => {
  const canShowQuestion = (question) => {
    if (!answers || !question.shownIf || (answers && answers.length === 0))
      return true;

    const dependenciesMatched = Object.keys(question.shownIf).map(
      (dependsOnQuestion) => {
        const matchedQuestion = answers.find(
          (answer) => answer.questionId === dependsOnQuestion
        );

        const allowedAnswers = question.shownIf[dependsOnQuestion].map((a) =>
          // TODO: This transformation should probably happen elsewhere.
          // Since the response is read from the URL, it ends up slugified
          String(a).toLowerCase().replace(' ', '-')
        );
        if (
          matchedQuestion &&
          allowedAnswers.includes(matchedQuestion.response)
        ) {
          return true;
        }
        return false;
      }
    );

    return dependenciesMatched.every((matched) => matched === true);
  };

  const getQuestionComponent = (question, index) => {
    let props = {
      questionAnswered: (response, extraProps) => {
        handleAnswered({
          question,
          response,
          index,
          extraProps,
        });
      },
    };
    switch (question.questionType) {
      case QUESTION_TYPE.ConfirmGuideQuestion:
        return {
          Component: questionComponents.ConfirmGuideQuestion,
          props: {
            guide: guide,
            productCount: prices.length,
            ...props,
          },
        };

      case QUESTION_TYPE.ConfirmationQuestion:
        return {
          Component: questionComponents.ConfirmAttributeQuestion,
          props: {
            guide: guide,
            attributes: question.questionData.attributes,
            ...props,
          },
        };
      case QUESTION_TYPE.PriceQuestion:
        return {
          Component: questionComponents.PriceQuestion,
          props: {
            ...props,
            prices,
          },
        };
      case QUESTION_TYPE.PriceQuestionFollowup:
        return {
          Component: questionComponents.PriceQuestionFollowup,
          props: {
            ...props,
            prices,
            onPriceSliderChange: updateMatchCount,
          },
        };
      case QUESTION_TYPE.AttributeQuestionFull:
        return {
          Component: questionComponents.AttributeQuestion,
          props: {
            ...props,
            attribute: {
              id: question.questionData.attributeId,
              name: question.questionData.attributeName,
            },
            asFullQuestion: true,
          },
        };
      case QUESTION_TYPE.AttributeChoiceQuestion:
        return {
          Component: questionComponents.AttributeChoiceQuestion,
          props: {
            ...props,
            questionText: question.questionData.text,
            questionSubtext: question.questionData.subtext,
            answers: question.questionData.answers,
            attributes: question.questionData.attributes,
          },
        };

      case QUESTION_TYPE.AttributeQuestion:
        return {
          Component: questionComponents.AttributeQuestion,
          props: {
            ...props,
            attribute: {
              id: question.questionData.attributeId,
              name: question.questionData.attributeName,
            },
          },
        };
      case QUESTION_TYPE.YesNoQuestion:
        return {
          Component: questionComponents.TextQuestion,
          props: {
            ...props,
            questionText: question.questionData.text,
            questionSubtext: question.questionData.subtext,
            buttonsText: ['Yes', 'No'],
            buttonClass: { 0: 'button-very', 1: 'button-not' },
          },
        };
      case QUESTION_TYPE.TextQuestion:
        return {
          Component: questionComponents.TextQuestion,
          props: {
            ...props,
            questionText: question.questionData.text,
            questionSubtext: question.questionData.subtext,
            buttonsText: question.questionData.answers,
          },
        };
      case QUESTION_TYPE.UserInputQuestion:
        return {
          Component: questionComponents.UserInputQuestion,
          props: {
            ...props,
            questionId: question.questionId,
            questionText: question.questionData.text,
            questionSubtext: question.questionData.subtext,
            inputPrompt: question.questionData.inputPrompt,
            cancelText: question.questionData.cancelText,
          },
        };
      default:
        return <></>;
    }
  };

  let visibleQuestions = questions
    .filter((question) => canShowQuestion(question))
    .map((question, index) => {
      const { Component, props } = getQuestionComponent(question, index);
      if (Component) {
        return <Component key={question.questionId} {...props} />;
      } else {
        // If the question type is not supported in the version, we'll skip it
        return null;
      }
    })
    .filter((question) => question);

  useEffect(() => {
    if (visibleQuestions.length === answers.length) {
      navigateToProductResults();
    }
  }, [visibleQuestions, answers, navigateToProductResults]);

  return <>{visibleQuestions}</>;
};

QuestionManager.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      questionId: PropTypes.string.isRequired,
    })
  ),
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      questionId: PropTypes.string,
      shownIf: PropTypes.shape({}),
      questionType: PropTypes.string.isRequired,
      questionData: PropTypes.shape({}),
    })
  ),
  prices: PropTypes.arrayOf(PropTypes.number),
  guide: PropTypes.shape({}),
  questionComponents: PropTypes.shape({}),
  handleAnswered: PropTypes.func.isRequired,
  updateMatchCount: PropTypes.func.isRequired,
};
