import React from 'react';
import PropTypes from 'prop-types';

import ProductInformation from './ProductInformation';

import {
  Attribute,
  ProductSummaryHeaderText,
  Divider,
} from './ProductResultsPageV73.styles.js';

import Box from 'components/common/Box';

const ProductSummary = ({
  product,
  attributeCount,
  primaryAttributes,
  withRating,
  withRecommendation,
}) => {
  return (
    <div>
      <ProductInformation
        product={product}
        primaryAttributes={primaryAttributes}
        withPrice
        withRating
      />
      <Divider />
      <>
        <div style={{ display: 'flex' }}>
          <Box mt={8} mb={16} style={{ width: '50%', textAlign: 'center' }}>
            <ProductSummaryHeaderText>
              Why people choose it
            </ProductSummaryHeaderText>
            {product.ratings
              .filter((rating) => rating.rating.unique)
              .map((rating) => (
                <Attribute key={rating.name}> {rating.name} </Attribute>
              ))}
          </Box>

          <div
            style={{ width: '1px', margin: '0 12px', background: '#e8e8e8' }}
          />
          <Box mt={8} mb={16} style={{ width: '50%', textAlign: 'center' }}>
            <ProductSummaryHeaderText>
              Potential concerns
            </ProductSummaryHeaderText>
            {product.ratings
              .filter((rating) => rating.rating.negative)
              .map((rating) => (
                <Attribute key={rating.name}> {rating.name} </Attribute>
              ))}
          </Box>
        </div>
      </>
    </div>
  );
};

ProductSummary.propTypes = {
  product: PropTypes.object.isRequired,
  attributeCount: PropTypes.number.isRequired,
  withRating: PropTypes.bool,
  withRecommendation: PropTypes.bool,
};
export default ProductSummary;
