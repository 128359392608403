import GuideIntroShortV70 from '../components/ReactGuide/GuideIntroShortV70';
import GuideIntroV75 from 'versions/v75/QuestionsPage/GuideIntro';

import LandingPagev77 from 'versions/v77/QuestionsPage/LandingPage';
import LandingPagev78 from 'versions/v78/QuestionsPage/LandingPage';

import ProductResultsPageV73 from 'pages/ProductResultsPage/v73/ProductResultsPageV73/ProductResultsPage';
import ProductResultsPageV75 from 'versions/v75/ProductResultsPageV75/ProductResultsPage';
import ProductResultsPageV76 from 'versions/v76/ProductResultsPageV76/ProductResultsPage';
import ProductResultsPageV78 from 'versions/v78/ProductResultsPage/ProductResultsPage';

import ProductDetailPageV73 from 'pages/ProductDetailPage/v73/ProductDetailPageV73';
// import ProductDetailPageV72 from 'pages/ProductDetailPage/v72/ProductDetailPageV72';

import QuestionHeader from '../components/ReactGuide/QuestionHeader';
import ProductRankingPreviewV70 from '../components/ReactGuide/ProductRankingPreviewV70';
import PriceQuestionV70 from '../components/questions/PriceQuestionV70';
import PriceQuestionFollowupV70 from '../components/questions/PriceQuestionFollowupV70';
import AttributeQuestionV70 from '../components/questions/AttributeQuestionV70';
import TextQuestionV70 from '../components/questions/TextQuestionV70';
import UserInputQuestion from 'components/questions/UserInputQuestion';
import AttributeChoiceQuestionV70 from 'components/questions/AttributeChoiceQuestionV70';
import ConfirmGuideQuestionV70 from '../components/questions/ConfirmGuideQuestionV70';
import ConfirmAttributeQuestionV70 from '../components/questions/ConfirmAttributeQuestionV70';

export const config = {
  storybook: {
    aboveFirstQuestion: [],
  },

  74: {
    aboveFirstQuestion: [GuideIntroShortV70],
    productResultsPage: ProductResultsPageV73,
    productDetailPage: ProductDetailPageV73,
    questionComponents: {
      PriceQuestion: PriceQuestionV70,
      PriceQuestionFollowup: PriceQuestionFollowupV70,
      AttributeQuestion: AttributeQuestionV70,
      TextQuestion: TextQuestionV70,
      UserInputQuestion: UserInputQuestion,
      AttributeChoiceQuestion: AttributeChoiceQuestionV70,
    },
    questionHeader: QuestionHeader,
    productRankingComponent: ProductRankingPreviewV70,
  },

  75: {
    aboveFirstQuestion: [GuideIntroV75],
    productResultsPage: ProductResultsPageV75,
    // productDetailPage: ProductDetailPageV73,
    questionComponents: {
      PriceQuestion: PriceQuestionV70,
      PriceQuestionFollowup: PriceQuestionFollowupV70,
      AttributeQuestion: AttributeQuestionV70,
      TextQuestion: TextQuestionV70,
      UserInputQuestion: UserInputQuestion,
      AttributeChoiceQuestion: AttributeChoiceQuestionV70,
      ConfirmGuideQuestion: ConfirmGuideQuestionV70,
    },
    questionHeader: QuestionHeader,
    productRankingComponent: ProductRankingPreviewV70,
  },
  76: {
    aboveFirstQuestion: [],
    productResultsPage: ProductResultsPageV76,
    // productDetailPage: ProductDetailPageV73,
    questionComponents: {
      PriceQuestion: PriceQuestionV70,
      PriceQuestionFollowup: PriceQuestionFollowupV70,
      AttributeQuestion: AttributeQuestionV70,
      TextQuestion: TextQuestionV70,
      UserInputQuestion: UserInputQuestion,
      AttributeChoiceQuestion: AttributeChoiceQuestionV70,
      ConfirmGuideQuestion: ConfirmGuideQuestionV70,
      ConfirmAttributeQuestion: ConfirmAttributeQuestionV70,
    },
    questionHeader: QuestionHeader,
    productRankingComponent: ProductRankingPreviewV70,
  },
  78: {
    aboveFirstQuestion: [],
    productResultsPage: ProductResultsPageV78,
    landingPageComponent: LandingPagev78,
    // productDetailPage: ProductDetailPageV73,
    questionComponents: {
      PriceQuestion: PriceQuestionV70,
      PriceQuestionFollowup: PriceQuestionFollowupV70,
      AttributeQuestion: AttributeQuestionV70,
      TextQuestion: TextQuestionV70,
      UserInputQuestion: UserInputQuestion,
      AttributeChoiceQuestion: AttributeChoiceQuestionV70,
      ConfirmGuideQuestion: ConfirmGuideQuestionV70,
      ConfirmAttributeQuestion: ConfirmAttributeQuestionV70,
    },
    questionHeader: QuestionHeader,
    productRankingComponent: ProductRankingPreviewV70,
  },
};

config[77] = { ...config[76] };
config[77].aboveFirstQuestion = [];
config[77].landingPageComponent = LandingPagev77;
