import React from 'react';
import PropTypes from 'prop-types';

import { PagePadding } from 'shared/styles';

import {
  ProgressBarContainer,
  ProgressIndicator,
  ProgressBar,
  ProgressText,
  GuideTitle,
} from './styles';

const QuestionHeader = ({ guideName, count, total }) => {
  return (
    <>
      <PagePadding>
        <GuideTitle>{guideName}</GuideTitle>
        <ProgressBarContainer>
          <ProgressIndicator id='progress_indicator'>&nbsp;</ProgressIndicator>
          <ProgressBar />
          <ProgressText>
            {count}/{total}
          </ProgressText>
        </ProgressBarContainer>
      </PagePadding>
    </>
  );
};

QuestionHeader.propTypes = {
  guideName: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default QuestionHeader;
