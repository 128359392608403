import React from 'react';
import PropTypes from 'prop-types';

import StarViz from 'shared/components/StarViz';

import {
  ProductTileContainer,
  ProductImage,
  ProductScore,
  ProductRating,
  SelectionTriangle,
} from './ProductTile.styles.js';

function ProductTile({ product, onClick, isSelected, index, hideScore }) {
  return (
    <ProductTileContainer
      onClick={() => onClick(product)}
      isSelected={isSelected}
    >
      {!hideScore && <ProductScore>{product.matched}</ProductScore>}
      <ProductImage src={product.image_url} />
      {product.primaryAttributes && (
        <ProductRating>
          <StarViz
            starRating={product.primaryAttributes.averageRating}
            numberReviews={product.primaryAttributes.numberOfReviews}
            shortened
          />
        </ProductRating>
      )}
      {isSelected && <SelectionTriangle />}
    </ProductTileContainer>
  );
}

ProductTile.propTypes = {
  product: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
};

export default ProductTile;
