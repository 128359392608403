import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  AttributeSummaryContainer,
  AttributeSummaryNameRow,
  AttributeSummaryName,
  AttributeSummarySubheader,
  AttributeSnippetPaddingBreaker,
  AttributeSnippetScroll,
  BottomBorder,
  ToggleState,
} from './styles';
import { GreenCheckIcon, RedXIcon, DownCaret, UpCaret } from 'shared/icons';

import AttributeSnippet from 'pages/ProductDetailPage/v72/AttributeSnippet';

const AttributeSummary = ({
  attribute,
  positiveSnippet,
  negativeSnippet,
  percentPositive,
  mentions,
  matched,
  startOpen,
}) => {
  const [showDetails, setShowDetails] = useState(startOpen || false);

  let RatingIcon;
  if (matched > 0) {
    RatingIcon = GreenCheckIcon;
  } else {
    RatingIcon = RedXIcon;
  }

  return (
    <>
      <AttributeSummaryContainer>
        <AttributeSummaryNameRow
          onClick={() => setShowDetails((details) => !details)}
        >
          <RatingIcon />
          <AttributeSummaryName>{attribute.name}</AttributeSummaryName>
          <ToggleState>{showDetails ? <UpCaret /> : <DownCaret />}</ToggleState>
        </AttributeSummaryNameRow>

        {showDetails && (
          <>
            <AttributeSummarySubheader>
              {mentions.toLocaleString()} mentions
              {percentPositive &&
                ', ' + percentPositive + '% of them are positive'}
            </AttributeSummarySubheader>

            <AttributeSnippetPaddingBreaker>
              <AttributeSnippetScroll>
                {positiveSnippet && (
                  <AttributeSnippet
                    attribute={attribute}
                    snippet={positiveSnippet}
                    percent={percentPositive}
                  />
                )}
                {negativeSnippet && (
                  <AttributeSnippet
                    attribute={attribute}
                    snippet={negativeSnippet}
                  />
                )}
              </AttributeSnippetScroll>
            </AttributeSnippetPaddingBreaker>
          </>
        )}
      </AttributeSummaryContainer>
      <BottomBorder />
    </>
  );
};

AttributeSummary.propTypes = {
  attribute: PropTypes.object.isRequired,
  positiveSnippet: PropTypes.object.isRequired,
  negativeSnippet: PropTypes.object.isRequired,
  percentPositive: PropTypes.number,
  mentions: PropTypes.number,
  matched: PropTypes.number,
  startOpen: PropTypes.bool,
};

export default AttributeSummary;
