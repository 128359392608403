import styled from 'styled-components';

import { PageTitle } from 'shared/styles';
import Card from 'components/common/Card';

export const PageContainer = styled.div`
  font-size: 18px;
`;

export const HomepageHero = styled.div`
  height: 60vh;
  display: flex;
  align-items: center;
  text-align: center;
  background: #3a3a3a;
`;

export const SiteName = styled.div`
  font-family: Georgia;
  font-size: 20px;
  color: #4eb6c9;
  line-height: 1;
`;

export const ProductCard = styled(Card)`
  padding: 24px 8px 16px 8px;
  border-radius: 8px;
  background: white;
`;

export const Spacer = styled.div`
  height: 40px;
  width: 100%;
`;

export const HomepageTitle = styled(PageTitle)`
  margin-bottom: 36px;
  color: #cce9b6;
`;

export const HomepageHeroText = styled.div`
  color: #ffffffb0;
`;

export const SignupText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #0f1617;
  margin-bottom: 16px;
`;

export const SplitBackground = styled.div`
  background: linear-gradient(180deg, white 50%, #f1f1f1 50%);
`;

// #f1f1f1
export const YellowBackground = styled.div`
  background: #fff7d8;
`;

export const GrayBackground = styled.div`
  background: #ffb50040;
`;

export const Section = styled.div`
  padding-top: 60px;
  padding-bottom: 80px;
`;

export const SectionTitle = styled(PageTitle)`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
`;

export const Bullets = styled.ul`
  list-style-type: none;
  margin-top: 10px;
`;

export const Bullet = styled.li`
  &:before {
    font-family: sans-serif;
    content: '✅︎';
    color: white;
    display: inline-block;
    width: 8px;
    padding-left: 3px;
    border-radius: 50%;
    margin-right: 1em;
    text-align: center;
  }
`;

export const SmallTextHeader = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Text = styled.div`
  color: rgb(54 49 43 / 80%);
`;
