import styled from 'styled-components';

export const ProductRatingContainer = styled.div`
  max-width: 700px;

  ${({ showPointer }) => showPointer && `cursor: pointer;`}
`;

export const ProductRatingHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ProductInformationContainer = styled.div`
  flex-grow: 1;
  align-self: flex-start;
`;

export const ImageContainerFullSize = styled.div`
  height: 80px;
  width: 80px;
  padding-right: 16px;
  align-self: center;
  flex-shrink: 0;
`;

export const ImageContainer = styled.div`
  height: 80px;
  width: 80px;
  min-width: 80px;
  margin-right: 8px;
`;

export const ProductImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const ProductBrand = styled.div`
  color: #0f1617;
  font-weight: 500;
  margin-bottom: 4px;
  letter-spacing: -0.2px;
  font-size: 1.2rem;
`;

export const ProductTitle = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #0f1617;
  margin-bottom: 4px;
`;

export const ProductRatingVisualizationContainer = styled.div``;

export const ProductRating = styled.div`
  font-size: 1.8rem;
  display: flex;
  color: #555555;
  ${({ small }) =>
    small &&
    `
    font-size: 80%;
    `}
`;

export const ProductRatingData = styled.span`
  margin-left: 4px;
`;

export const ProductInformationAdditonalContainer = styled.div`
  margin-top: 8px;
`;

export const ProductPrice = styled.div`
  display: flex;
  color: #555555;
  line-height: 18px;
  letter-spacing: -0.2px;
`;
