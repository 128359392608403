export class API {
  serializeAnswers(answers) {
    return answers
      .filter((a) => a.question) // Ignore if there's no question data (eg GuideInterstitial)
      .map((a) => {
        return {
          questionId: a.questionId,
          questionType: a.question.questionType,
          meta: a.question.meta,
          response: a.response,
        };
      });
  }

  postData(endpoint, data, onReturn) {
    console.log(JSON.stringify(data));
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'same-origin',
    })
      .then((response) => response.json())
      .then((data) => {
        onReturn && onReturn(data);
      });
  }

  getGuideInfo({ guideName, primaryAttributeName, onReturn }) {
    fetch('/api/guide/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        quiz_name: guideName,
        primaryAttributeName,
      }),
      credentials: 'same-origin',
    })
      .then((response) => response.json())
      .then((data) => {
        onReturn && onReturn(data);
      });
  }

  // This returns a ranked set of products that is used in the otherOptions panel
  getProductResults = (
    guideId,
    productId,
    answers,
    minPrice,
    maxPrice,
    requestMetadata,
    includeProduct,
    onReturn
  ) => {
    fetch('/api/product/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        quiz_id: guideId,
        product_id: productId,
        answers: this.serializeAnswers(answers),
        min_price: minPrice,
        max_price: maxPrice,
        includeProduct,
      }),
      credentials: 'same-origin',
    })
      .then((response) => response.json())
      .then((data) => {
        data.requestMetadata = requestMetadata;
        return onReturn && onReturn(data);
      });
  };

  // This returns a ranked set of products that is used in the otherOptions panel
  getAttributeSnippets(guideId, attributeName, onReturn) {
    fetch('/api/snippets/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        quiz_id: guideId,
        attribute: attributeName,
      }),
      credentials: 'same-origin',
    })
      .then((response) => response.json())
      .then((data) => onReturn && onReturn(data));
  }

  getAttributes(guideId, attributeName, onSuccess, onError) {
    fetch('/api/attribute/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        quiz_id: guideId,
        name: attributeName,
      }),
      credentials: 'same-origin',
    })
      .then((response) => {
        if (response.status === 404) {
          onError &&
            onError({
              isError: true,
              status: '404',
              message: 'Attribute Not Found',
            });
        } else {
          return response.json();
        }
      })
      .then((data) => onSuccess && onSuccess(data));
  }

  saveProduct(guideId, productId, attributes, email, onReturn) {
    fetch('/api/save/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        quiz_id: guideId,
        product_id: productId,
        answers: this.serializeAnswers(attributes),
        email: email,
      }),
      credentials: 'same-origin',
    })
      .then((response) => response.json())
      .then((data) => onReturn && onReturn(data));
  }

  saveUserData(email, source, context, quizId, answers, onReturn) {
    fetch('/api/user/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        source: source,
        context: context,
        quiz_id: quizId,
        answers: answers,
      }),
      credentials: 'same-origin',
    })
      .then((response) => response.json())
      .then((data) => onReturn && onReturn(data));
  }

  getSavedGuide(saveId, onReturn) {
    fetch(`/loadsave/?saveId=${saveId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    })
      .then((response) => response.json())
      .then((data) => onReturn && onReturn(data));
  }

  getSnippetsReview(guideId, productId, attributes, onReturn) {
    fetch('/api/reviews/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        quiz_id: guideId,
        product_id: productId,
        answers: attributes,
      }),
      credentials: 'same-origin',
    })
      .then((response) => response.json())
      .then((data) => onReturn && onReturn(data));
  }
}
