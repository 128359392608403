import React from 'react';
import PropTypes from 'prop-types';
import Buttons from '../common/StandardizedButtonsV70';
import QuestionFrameV70 from './QuestionFrameV70';
import {
  MainQuestionText,
  AttributeText,
  ShortenedAttributeIntro,
} from './styles';

const AttributeQuestionV70 = ({
  asFullQuestion,
  attribute,
  questionAnswered,
  contentBefore,
  contentAfter,
}) => {
  const question = asFullQuestion ? (
    <MainQuestionText>
      How important is an option that's good for&nbsp;
      <AttributeText>{attribute.name.toLowerCase()}</AttributeText>?
    </MainQuestionText>
  ) : (
    <MainQuestionText>
      <ShortenedAttributeIntro>{`How important is... `}</ShortenedAttributeIntro>
      <AttributeText>{attribute.name}</AttributeText>
    </MainQuestionText>
  );

  const buttons = (
    <Buttons
      buttons={[
        { text: 'Not', value: 'not' },
        { text: 'Somewhat', value: 'somewhat' },
        { text: 'Very', value: 'very' },
      ]}
      questionAnswered={questionAnswered}
      horizontal={true}
    />
  );

  return (
    <>
      <QuestionFrameV70
        question={question}
        buttons={buttons}
        contentBefore={contentBefore}
        contentAfter={contentAfter}
      />
    </>
  );
};

AttributeQuestionV70.propTypes = {
  asFullQuestion: PropTypes.bool,
  attribute: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  questionAnswered: PropTypes.func.isRequired,
  contentBefore: PropTypes.any,
  contentAfter: PropTypes.any,
};

export default AttributeQuestionV70;
