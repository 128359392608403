import styled from 'styled-components';

export const GuideIntroTitle = styled.div`
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 2rem;
`;

export const GuideIntroSectionTitle = styled.div`
  font-size: 2rem;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 2rem;
`;

export const GuideIntroText = styled.div`
  line-height: 1.5;
  font-size: 1.4rem;
  margin-bottom: 2rem;
`;

export const ButtonContainer = styled.div`
  width: 75%;
  max-width: 250px;
  position: sticky;
  top: 20px;
  margin: auto;
`;

export const SectionContent = styled.div`
  margin-bottom: 1.5rem;
`;

export const HowItWorksSection = styled(SectionContent)`
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const HowItWorksSectionHeader = styled.div`
  font-weight: bold;
`;

export const HowItWorksSectionText = styled.div``;

export const Section = styled.div`
  margin-bottom: 4rem;
  ${({ topBorder }) =>
    topBorder &&
    `border-top: 1px solid #e8e8e8;
    padding-top: 2rem
    `};

  ${({ border }) =>
    border &&
    `border: 2px solid #e8e8e8;
     border-radius: 8px;
     padding: 2rem 2rem;
    `};
  ${({ centered }) => centered && `text-align: center`};
`;

export const ProductBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 24px -4px rgba(143, 143, 143, 0.5);
  border-radius: 8px;
  padding: 2rem 2rem;
  margin-top: 2rem;
  text-align: left;
`;
