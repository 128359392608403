import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getAttributeCount } from 'components/ProductDetailPage/constants';

const PRICE_RANGE = 'price-range';
const PRICE_QUESTION = 'price-question';

const getPriceRange = (answers) => {
  if (!answers) return {};
  const priceRangeAnswer = answers.find(
    (item) => item.questionId === PRICE_RANGE
  );

  // NOTE: getPriceRange is used as component props. Be careful not to create
  // new objects here when the value itself doesn't change
  return priceRangeAnswer
    ? priceRangeAnswer.response
    : { min: null, max: null };
};

export const withAnswerService = (Component) => (props) => {
  const { match, history, location, guideData } = props;
  const query = useLocation().search;

  const {
    params: { urlAnswers },
  } = match;

  // NOTE: answers === null means answers haven't been parsed. answers === []
  // means there are no answers
  const [answers, setAnswers] = useState(null);

  const urlToAnswer = (urlAnswer, questions) => {
    const answerParts = urlAnswer.split('.');
    const questionId = answerParts[0];
    const foundQuestion = questions.find((q) => q.questionId === questionId);
    return questionId === PRICE_RANGE
      ? {
          questionId,
          question: foundQuestion,
          response: {
            minPrice: answerParts[1] === 'any' ? null : answerParts[1],
            maxPrice: answerParts[2] === 'any' ? null : answerParts[2],
          },
        }
      : {
          questionId,
          question: foundQuestion,
          response: answerParts[1],
        };
  };

  const answerToUrl = (question) => {
    const { questionId, response } = question;

    if (questionId === PRICE_RANGE) {
      const { minPrice, maxPrice } = response;
      return `${questionId}.${minPrice ? minPrice : 'any'}.${
        maxPrice ? maxPrice : 'any'
      }`;
    }

    let normalizedResponse = String(response);
    // TODO: Make a more explicit way to hide data in URL
    // question.questionData && question.questionData.userEntry ? '' : response;

    return `${questionId}.${normalizedResponse
      .toLowerCase()
      .replace(/ /g, '-')}`;
  };

  const updateAnswer = (newAnswer) => {
    const { pathname } = location;
    const { questionId, newAnswerIndex } = newAnswer;
    const paths = pathname.split('/');

    let newPaths = !pathname.includes('answers')
      ? [...paths, 'answers']
      : [...paths];

    const oldAnswer = paths.find((p) => p.includes(questionId));
    if (oldAnswer) {
      newPaths.splice(paths.indexOf(oldAnswer), 1, answerToUrl(newAnswer));
    } else {
      newPaths.splice(
        newAnswerIndex || paths.length + 1,
        0,
        answerToUrl(newAnswer)
      );
    }

    history.push(newPaths.join('/') + query);
  };

  // NOTE: if we add a feature to "shortcut" to the product page,
  // this function needs to use history.push when shortcutting.
  // Replace is used when all answers are completed because that URL
  // should represent the product page
  const navigateToProductResults = () => {
    const { pathname } = location;

    history.replace([pathname, 'product'].join('/') + query);
  };

  const updatePriceRange = (minPrice, maxPrice) => {
    const paths = location.pathname.split('/');
    const priceQuestionIndex = paths.indexOf(
      paths.find((p) => p.includes(PRICE_QUESTION))
    );
    updateAnswer({
      questionId: PRICE_RANGE,
      response: {
        minPrice,
        maxPrice,
      },
      newAnswerIndex: priceQuestionIndex + 1,
    });
  };

  const didAnswersChange = (oldAnswers, newAnswers) => {
    if (oldAnswers.length !== newAnswers.length) {
      return true;
    }

    for (let i = 0; i < oldAnswers.length; i++) {
      let oldAnswer = oldAnswers[i];
      let newAnswer = newAnswers[i];

      if (
        oldAnswer.id !== newAnswer.id ||
        oldAnswer.response !== newAnswer.response
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (guideData) {
      if (urlAnswers) {
        const parsedUrlAnswers = urlAnswers
          .split('/')
          .map((urlAnswer) => urlToAnswer(urlAnswer, guideData.questions));
        // If the answers haven't changed, we don't want to trigger a
        // render. This is particularly important on product pages because it
        // will trigger unnecessary API calls
        if (!answers || didAnswersChange(answers, parsedUrlAnswers)) {
          setAnswers(parsedUrlAnswers);
        }
      } else {
        setAnswers([]);
      }
    }
    // TODO: useEffect complains about the dependency on answers, but adding
    // answers to the array causes maximum depth errors
    // eslint-disable-next-line
  }, [guideData, urlAnswers]);

  return (
    <>
      {answers && (
        <Component
          {...props}
          answers={answers}
          attributeCount={getAttributeCount(guideData.questions)}
          addAnswerToUrl={updateAnswer}
          updatePriceRange={updatePriceRange}
          priceRange={getPriceRange(answers)}
          navigateToProductResults={navigateToProductResults}
        />
      )}
    </>
  );
};
