import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import ReactModal from 'react-modal';
ReactModal.setAppElement('#root');

const Modal = ({
  isOpen,
  onRequestClose,
  className,
  style,
  children,
  closeTimeoutMS,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => (document.body.style.overflow = 'unset');
  }, [isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={className}
      shouldReturnFocusAfterClose={false}
      style={style}
      closeTimeoutMS={closeTimeoutMS}
    >
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  closeTimeoutMS: PropTypes.number,
};

export default Modal;
