import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: ${({ wrapButtons }) => (wrapButtons ? 'block' : 'flex')};
  margin-top: 32px;
  width: 60%;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
