import styled from 'styled-components';

export const ProductRatingContainer = styled.div`
  display: flex;
  max-width: 700px;

  ${({ vertical }) =>
    vertical &&
    `
  flex-direction: column;

  `}

  ${({ onClick }) =>
    onClick &&
    `
    cursor: pointer;
  `}
`;

export const ImageContainerFullSize = styled.div`
  height: 300px;
  width: 300px;
  margin-bottom: 32px;
  align-self: center;
`;

export const ImageContainer = styled.div`
  height: 80px;
  width: 80px;
  min-width: 80px;
  margin-right: 8px;
`;

export const ProductImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const ProductBrand = styled.div`
  color: #666666;
  margin-bottom: 4px;
  letter-spacing: -0.2px;
  font-weight: 500;
`;

export const ProductTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0f1617;
  margin-bottom: 4px;
`;

export const ProductRating = styled.div`
  font-size: 1.2rem;
  display: flex;
  color: #666666;
`;

// BetterBox
export const ProductRatingData = styled.span`
  margin-left: 4px;
`;

export const ProductInformationAdditonalContainer = styled.div`
  margin-top: 8px;
`;

export const ProductPrice = styled.div`
  font-size: 12px;
  display: flex;
  color: #0f1617;
  line-height: 18px;
  letter-spacing: -0.2px;
`;
