import React, { useEffect } from 'react';

import { PagePadding, PageTitle } from 'shared/styles';
import { SecondaryTitle, PageCopy, Paragraph } from './AboutPage.styles';

import { Tracking } from 'components/tracking';

import Footer from 'pages/Homepage/Footer';

const AboutPage = () => {
  useEffect(() => {
    const tracking = Tracking();
    tracking.viewedAboutPage();
  }, []);

  return (
    <>
      <PagePadding>
        <PageTitle> About Perspect </PageTitle>
        <PageCopy>
          <Paragraph>
            Perspect simplifies product research by helping you better
            understand your needs and recommending products that match them. We
            extensively research user reviews to figure out what the most common
            needs are and create guides that walk you through the possibilities.
          </Paragraph>
          <Paragraph>
            Once you've answered the questions in a guide, we show you the
            products which most closely match your needs as determined by other
            users like you. If you want to learn more about what these users
            say, simply click through to read their reviews.
          </Paragraph>
          <Paragraph>
            This service is free to use. If you find a product that meets your
            needs and end up buying it off Amazon, we receive a small percentage
            of the total. The price you pay does not change, and helps us pay
            for servers and keep the lights on.
          </Paragraph>
          <SecondaryTitle> We'd love to hear from you! </SecondaryTitle>
          <Paragraph>
            Send us your thoughts, questions, feedback or requests for more
            guides to{' '}
            <a href='mailto:help@getperspect.com'>help@getperspect.com</a>.
          </Paragraph>
        </PageCopy>
      </PagePadding>
      <Footer />
    </>
  );
};

export default AboutPage;
