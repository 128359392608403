import React from 'react';
import PropTypes from 'prop-types';

import ProductSummary from './ProductSummaryWithUnique';
import { PagePadding } from 'shared/styles';
import {
  Attribute,
  Divider,
  ResultsPageHeader,
  ResultsPageTitle,
  ResultsPageSubtitle,
  ResultsHeaderAttributeContainer,
  ResultsCount,
  ProductResultContainer,
  ProductListContainer,
} from './ProductResultsPageV73.styles.js';

import PrimaryButton from 'shared/components/PrimaryButton';

// TODO: Review products[0] call below - it's a hack to address the primary attribute
// summary should actually be from guideData
const ProductResultsPage = ({
  products,
  matchedReviewCount,
  primaryAttributes,
  selectedAttributeCount,
  onSelectProduct,
  guideData,
}) => {
  return (
    <>
      <PagePadding>
        <ResultsPageHeader>
          <ResultsPageTitle> {guideData.guide.title} Matched </ResultsPageTitle>
          <ResultsPageSubtitle>
            {matchedReviewCount.toLocaleString()} of{' '}
            {guideData.guide.reviews.toLocaleString()} reviews match your needs
            <ResultsHeaderAttributeContainer>
              {primaryAttributes.map((a) => (
                <Attribute inverted key={a}>
                  {a}
                </Attribute>
              ))}
            </ResultsHeaderAttributeContainer>
          </ResultsPageSubtitle>
          <ResultsCount> {products.length} products </ResultsCount>
        </ResultsPageHeader>

        <ProductListContainer>
          {products.map((p) => (
            <ProductResultContainer key={p.id}>
              <ProductSummary
                product={p}
                primaryAttributes={primaryAttributes}
                attributeCount={selectedAttributeCount}
              />
              <Divider />
              <PrimaryButton
                onClick={() => {
                  window._tracking.viewedProductsPage({
                    featured_product: p.id,
                  });
                  window.location.href = p.url;
                }}
                orange
              >
                Read more
              </PrimaryButton>
            </ProductResultContainer>
          ))}
        </ProductListContainer>
      </PagePadding>
    </>
  );
};

ProductResultsPage.propTypes = {
  products: PropTypes.array.isRequired,
  attributeCount: PropTypes.number.isRequired,
};

export default ProductResultsPage;
