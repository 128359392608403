import styled from 'styled-components';
import Card from 'components/common/Card';

export const InputFieldContainer = styled(Card)`
  display: flex;
  height: 48px;
  padding: 0px;
  border-radius: 8px !important;
  overflow: hidden;

  ${({ noBottomRadius }) =>
    noBottomRadius &&
    `
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  `}
`;

export const InputFieldElement = styled.input`
  border-radius: 8px 0px 0px 8px !important;
  border: 0px !important;
  height: 48px !important;
  line-height: 48px;
  color: #666666;
  font-size: 16px;
  width: 100%;
  ${({ noBottomRadius }) =>
    noBottomRadius &&
    `
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;

  `}
`;

export const Success = styled.div`
  color: #50b033;
  font-size: 16px;
  font-weight: bold;
  margin-top: 16px;
`;

export const Fail = styled.div`
  color: #ea3223;
  font-size: 16px;
  font-weight: bold;
  margin-top: 16px;
`;
