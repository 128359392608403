import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ContentWindow, QuestionContainer } from './styles';

import { PageContent } from 'shared/styles';
import { Carousel } from './Carousel';
import { QuestionManager } from './QuestionManager';
import AppContext from 'AppContext';

const GuideLayout = (props) => {
  const {
    state: { versionConfig },
  } = useContext(AppContext);

  const [slideIndex, setSlideIndex] = useState(0);
  const [landingPageDismissed, setLandingPageDismissed] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, [slideIndex]);

  useEffect(() => {
    if (props.answers) setSlideIndex(props.answers.length);
  }, [props.answers]);

  if (!versionConfig) return <></>;

  const {
    aboveFirstQuestion,
    questionComponents,
    questionHeader,
    productRankingComponent,
    landingPageComponent,
  } = versionConfig;

  const QuestionHeaderComponent = questionHeader ? questionHeader : null;
  const ProductRankingComponent = productRankingComponent
    ? productRankingComponent
    : null;

  if (
    landingPageComponent &&
    !landingPageDismissed &&
    props.answers.length === 0
  ) {
    const LandingPageComponent = landingPageComponent;
    return (
      <LandingPageComponent
        guide={props.guideData && props.guideData.guide}
        questions={props.guideData.questions}
        products={props.products}
        productCount={props.guideData.productCount}
        onContinue={(position) => {
          setLandingPageDismissed(true);
          window._tracking.dismissLandingPage(position);
        }}
      />
    );
  }

  return (
    <PageContent>
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        {QuestionHeaderComponent && (
          <QuestionHeaderComponent
            guideName={props.guideData ? props.guideData.guide.title : ''}
            count={slideIndex + 1}
            total={props.guideData.questions.length}
          />
        )}

        <ContentWindow id='content_window'>
          {props.guideData && (
            <Carousel slide={slideIndex}>
              {slideIndex === 0 && (
                <div style={{ width: '100vw' }}>
                  {aboveFirstQuestion.map((Component, index) => (
                    <Component
                      key={'aboveFirstQuestion' + index}
                      guideName={props.guideData.guide.title}
                      guideReviews={props.guideData.guide.reviews}
                    />
                  ))}
                </div>
              )}
              <QuestionContainer id='question_container'>
                <QuestionManager
                  answers={props.answers}
                  questions={props.guideData.questions}
                  prices={props.guideData.prices}
                  guide={props.guideData.guide}
                  handleAnswered={props.handleAnswered}
                  updateMatchCount={props.updateMatchCount}
                  advanceGuide={props.advanceGuide}
                  questionComponents={questionComponents}
                  navigateToProductResults={props.navigateToProductResults}
                />
              </QuestionContainer>
            </Carousel>
          )}
        </ContentWindow>

        <React.Suspense fallback={'Loading...'}>
          {props.products ? (
            <ProductRankingComponent
              products={props.products}
              fadeProducts={false}
              productCount={props.guideData.productCount}
              matchedCount={props.matchedCount}
              matchedText={
                props.guideData.guide.title.toLowerCase() + ' matched'
              }
            />
          ) : (
            <></>
          )}
        </React.Suspense>
      </div>
    </PageContent>
  );
};

GuideLayout.defaultProps = {
  matchedAttribCount: 0,
};

GuideLayout.propTypes = {
  guideData: PropTypes.object,
  answers: PropTypes.array,
  products: PropTypes.array,
  matchedCount: PropTypes.number,
  attributeCount: PropTypes.number.isRequired,
  matchedAttribCount: PropTypes.number.isRequired,
  prices: PropTypes.array,
  priceRange: PropTypes.object,
  productDetailHeader: PropTypes.elementType,
};

export default withRouter(GuideLayout);
