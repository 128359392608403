import React from 'react';
import PropTypes from 'prop-types';

import ProductInformation from './ProductInformation';
import StarViz from 'shared/components/StarViz';
import PrimaryButton from 'shared/components/PrimaryButton';

import {
  Attribute,
  AttributeTag,
  ProductSummaryHeaderText,
  Divider,
} from './ProductResultsPageV76.styles.js';

import {
  HorizontalDivider,
  TwoColumnLayout,
  Column,
  IconWrapper,
} from './ProductSummary.styles.js';

import { GreenCheckIcon, RedXIcon } from 'shared/icons';

const ProductSummary = ({
  product,
  primaryAttributes,
  selectedAttributes,
  withRating,
  withRecommendation,
  onSelectProduct,
  hideReadMore,
}) => {
  const ratingsFromAnswers = product.ratings.filter(
    (rating) =>
      rating.rating.fromAnswer && !primaryAttributes.includes(rating.name)
  );

  return (
    <div>
      <ProductInformation
        product={product}
        primaryAttributes={primaryAttributes}
        withPrice
        withRating
        onClick={() => onSelectProduct && onSelectProduct(product)}
        showPointer={onSelectProduct}
      />
      {ratingsFromAnswers.length > 0 && (
        <>
          <Divider />
          <ProductSummaryHeaderText>
            Additional Selections
          </ProductSummaryHeaderText>

          {ratingsFromAnswers.map((rating) => (
            <AttributeTag key={rating.name}>
              <IconWrapper>
                {rating.rating.score === 2 && (
                  <>
                    <GreenCheckIcon />
                    <GreenCheckIcon style={{ marginLeft: '-2px' }} />
                  </>
                )}
                {rating.rating.score === 1 && <GreenCheckIcon />}
                {rating.rating.score < 1 && <RedXIcon />}
              </IconWrapper>
              {rating.name + ' '}
              <StarViz starRating={rating.rating.averageStarRating} shortened />
            </AttributeTag>
          ))}
        </>
      )}

      <Divider />

      <TwoColumnLayout>
        <Column>
          <ProductSummaryHeaderText>
            Why people chose it
          </ProductSummaryHeaderText>
          {product.ratings
            .filter((rating) => rating.rating.unique)
            .map((rating) => (
              <Attribute twoLine key={rating.name}>
                {rating.name}
                <StarViz
                  starRating={rating.rating.averageStarRating}
                  numberReviews={rating.rating.reviews}
                  shortened
                  inline
                  lightened
                />
              </Attribute>
            ))}
        </Column>

        <HorizontalDivider />

        <Column>
          <ProductSummaryHeaderText>
            Potential concerns
          </ProductSummaryHeaderText>
          {product.ratings
            .filter((rating) => rating.rating.negative)
            .map((rating) => (
              <Attribute twoLine key={rating.name}>
                {rating.name}
                <StarViz
                  starRating={rating.rating.averageStarRating}
                  numberReviews={rating.rating.reviews}
                  shortened
                  inline
                  lightened
                />
              </Attribute>
            ))}
        </Column>
      </TwoColumnLayout>
      {!hideReadMore && (
        <>
          <Divider />
          <PrimaryButton onClick={() => onSelectProduct(product)} orange>
            Read more
          </PrimaryButton>
        </>
      )}
    </div>
  );
};

ProductSummary.propTypes = {
  product: PropTypes.object.isRequired,
  withRating: PropTypes.bool,
  withRecommendation: PropTypes.bool,
  onSelectProduct: PropTypes.func,
};
export default ProductSummary;
