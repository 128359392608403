import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  color: #0f1617;
`;

export const PagePadding = styled.div`
  padding: 0px 16px;
  background: ${(props) =>
    props.blueBackground ? 'rgb(77, 182, 200)' : 'white'};
`;

export const Link = styled.div`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.1px;
  color: #4eb6c9;
  margin-top: 16px;
`;
