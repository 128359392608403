import { SET_STARTING_PRODUCT } from './actions';

export const guideInitialState = {};

export const guideReducer = (state = guideInitialState, action) => {
  switch (action.type) {
    case SET_STARTING_PRODUCT: {
      return { ...state, startingProduct: action.payload };
    }
    default: {
      return state;
    }
  }
};
