import React from 'react';
import PropTypes from 'prop-types';

import { PagePadding } from 'shared/styles';
import { GuideIntroText } from './styles';

const GuideIntroShortV70 = ({ guideName, guideReviews }) => {
  return (
    <PagePadding>
      <GuideIntroText>
        Four stars overall doesn't mean four stars for you. See top-ranked
        products based on your skin type and concerns. (
        {guideReviews.toLocaleString()} reviews)
      </GuideIntroText>
    </PagePadding>
  );
};

GuideIntroShortV70.propTypes = {
  guideName: PropTypes.string.isRequired,
  guideReviews: PropTypes.number.isRequired,
};

export default GuideIntroShortV70;
