import React from 'react';
import PropTypes from 'prop-types';

import StarViz from 'shared/components/StarViz';

import {
  ProductTileContainer,
  ProductImage,
  ProductScore,
  ProductRating,
} from './ProductTile.styles.js';

function ProductTile({ product, onClick, isSelected, index }) {
  return (
    <ProductTileContainer
      onClick={() => onClick(product)}
      isSelected={isSelected}
    >
      <ProductScore>{product.matched}</ProductScore>

      <ProductImage src={product.image_url} />
      <ProductRating>
        <StarViz
          starRating={product.primaryAttributes.averageRating}
          lightened
        />
      </ProductRating>
    </ProductTileContainer>
  );
}

ProductTile.propTypes = {
  product: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
};

export default ProductTile;
