import amplitude from 'amplitude-js';

amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_TOKEN, null, {
  includeUtm: true,
  includeReferrer: true,
});

const amplitudeInst = amplitude.getInstance();

function translateProps(props) {
  if (!props) return {};
  let _props = Object.assign({}, props);

  let _translated = {
    guideId: props.id,
    guideName: props.name,
    eid: props.eid,
    chid: props.chid,
    trackingCode: props.trackingCode,
  };
  delete _props.id;
  delete _props.name;
  delete _props.eid;
  delete _props.chid;
  delete _props.trackingCode;

  return Object.assign(_translated, _props);
}

export var Tracking = function (props) {
  let translatedProps = translateProps(props);
  translatedProps = Object.assign(translatedProps, {
    'Screen Density': window.devicePixelRatio,
    'Available Screen Height': window.screen.availHeight,
    'Available Screen Width': window.screen.availWidth,
  });

  return {
    properties: translatedProps,

    track: function (event, props, callback, err_callback) {
      var mergedProps = {};
      if (props) Object.assign(mergedProps, props);

      mergedProps = Object.assign(mergedProps, this.properties);

      amplitudeInst &&
        amplitudeInst.logEvent(event, mergedProps, callback, err_callback);

      window.FS && window.FS.event(event, mergedProps);

      process.env.NODE_ENV === 'development' &&
        console.log(event + ':\n' + JSON.stringify(mergedProps));
    },

    startGuide: function (props) {
      this.track('Started guide', props);

      window.FS &&
        window.FS.setUserVars({
          guideId_int: parseInt(this.properties.guideId),
          guideName_str: this.properties.guideName,
          experimentId_int: parseInt(this.properties.eid),
          trackingCode_str: this.properties.trackingCode,
        });
    },

    dismissLandingPage: function (position) {
      this.track('Dismiss landing page', {
        position: position,
      });
    },

    tapLandingPageExampleProduct: function (productId) {
      this.track('Tapped landing page example product', {
        productId: productId,
      });
    },

    setPriceFilter: function (props) {
      this.track('Set price filter', props);
    },

    answerQuestion: function (
      questionId,
      questionType,
      attribute,
      preferenceLevel,
      index,
      extraProps
    ) {
      this.track('Answered question', {
        questionId: questionId,
        questionType: questionType,
        attribute: attribute,
        text: preferenceLevel,
        index: index,
        ...extraProps,
      });
    },

    startExtraQuestions: function (numQuestions) {
      this.track('Started extra questions', {
        extraQuestionCount: numQuestions,
      });
    },

    giveFeedback: function (response, page) {
      this.track('Feedback - What you were expecting', {
        response: response,
        page: page,
      });
    },

    feedbackOnProductResult: function (response) {
      this.track('Feedback - Is this a good fit', {
        response: response,
      });
    },

    viewedProductResultsPage: function (props) {
      this.track('Viewed product results page', props);
    },

    displayedProducts: function (productIds, props) {
      this.track('Displayed products on product results page', {
        productIds: productIds,
        ...props,
      });
    },

    viewedProductsPage: function (props) {
      this.track('Viewed products page', props);
    },

    sortProductsBy: function (attributeId, attributeName) {
      this.track('Sorted products by attribute', {
        attributeId: attributeId,
        attributeName: attributeName,
      });
    },

    choseProduct: function (productId, productUrl, position, score) {
      // let url =
      //   '/product/' + productId + '/?quiz_id=' + this.properties.guideId;
      //
      let callback = () => {
        window.gtag_report_conversion(productUrl, score);
      };

      this.track(
        'Chose product',
        {
          productId: productId,
          destinationUrl: productUrl,
          position: position,
          score: score,
        },
        callback,
        callback // If amplitude is unreachable, still follow callback
      );
    },

    viewedOtherOption: function (quiz_id, product_id) {
      this.track('Tapped Other Options', {
        quizId: quiz_id,
        productId: product_id,
      });
    },

    readFullReview: function (productId, attribute, sentiment, sourceId) {
      this.track('Viewed customer review', {
        productId: productId,
        attribute: attribute,
        sentiment: sentiment,
        sourceId: sourceId,
      });
    },

    scrollAdditionalOptions: function (prevIndex, nextIndex, direction) {
      this.track('Scroll additional options', {
        prev_index: prevIndex,
        next_index: nextIndex,
        direction: direction,
      });
    },

    togglePriceRange: function (state) {
      this.track('Toggle price range filter', {
        state: state,
      });
    },

    scrollSnippets: function (
      beforeAttribute,
      afterAttribute,
      section,
      currentSlide,
      nextSlide
    ) {
      this.track('Scroll snippets', {
        beforeAttribute: beforeAttribute,
        nextAttribute: afterAttribute,
        section: section,
        currentSlide: currentSlide,
        nextSlide: nextSlide,
      });
    },

    changeProductPageTab: function (lastTab, newTab) {
      this.track('Change product page tab', {
        lastTab: lastTab,
        newTab: newTab,
      });
    },

    lookupAttribute: function (attribute, productId) {
      this.track('Lookup attribute for product', {
        attribute: attribute.name,
        attributeId: attribute.id,
        productId: productId,
      });
    },

    filterSnippetsByAttribute: function (attribute) {
      this.track('Filter snippets by attribute', {
        attribute: attribute.name,
        attributeId: attribute.id,
      });
    },

    toggleSnippetSentiment: function (attribute, sentiment) {
      this.track('Toggle snippet sentiment', {
        attribute: attribute,
        sentiment: sentiment,
      });
    },

    seeMoreSnippets: function (attribute, sentiment) {
      this.track('Tap see more snippets', {
        attribute: attribute,
        sentiment: sentiment,
      });
    },

    openedSaveModal: function (productId) {
      this.track('Opened save modal', {
        productId: productId,
      });
    },

    savedProduct: function (productId) {
      this.track('Saved product', {
        productId: productId,
      });
    },

    openedSaveUrl: function (saveId) {
      this.track('Opened save URL', {
        saveId: saveId,
      });
    },

    requestedUnknownAttribute: function (attribute, email) {
      this.track('Requested unknown attribute', {
        attribute: attribute,
        email: email,
      });
    },

    openedChangePriceRangeModal: function (product, currentMin, currentMax) {
      this.track('Opened change price range modal', {
        productPrice: product.price,
        currentMinPrice: currentMin,
        currentMaxPrice: currentMax,
      });
    },

    openedAskAboutProductModal: function (product) {
      this.track('Opened ask about product modal', {
        productId: product.id,
      });
    },

    showedDiscoverNewBrandsModal: function () {
      this.track('Showed discover new brands modal', {});
    },

    askedAboutProduct: function (product, question) {
      this.track('Asked about product', {
        productId: product.id,
        question: question,
      });
    },

    selectedProductConfirmationNav: function (product, navButton) {
      this.track('Selected product confirmation navigation', {
        productId: product.id,
        ...navButton,
      });
    },

    viewedAboutPage: function () {
      this.track('Viewed about page');
    },

    viewedHomepage: function () {
      this.track('Viewed homepage');
    },
  };
};
