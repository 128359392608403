import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/font.css';
import './assets/css/normalize.css';
import './assets/css/skeleton.css';
import './index.css';
import App from './App';
// serviceWorker throwing occasional exceptions
// import * as serviceWorker from './serviceWorker';

window.currTime = Date.now();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
