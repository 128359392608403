import React from 'react';

const Bar = ({ label, percent, secondPercent, diff }) => {
  return (
    <div style={{ marginBottom: '10px', fontSize: '14px' }}>
      <div> {label} </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '100%' }}>
          <div
            style={{
              width: percent,
              height: '8px',
              backgroundColor: '#7ce0ff',
            }}
          ></div>

          <div
            style={{
              width: secondPercent || percent,
              height: '8px',
              backgroundColor: '#ddd',
            }}
          ></div>
        </div>
        <div> {diff} </div>
      </div>
    </div>
  );
};

const HomepageChart = () => {
  return (
    <div
      style={{
        padding: '24px',
        borderRadius: '6px',
        border: '1px solid #deedee',
        background: 'white',
      }}
    >
      <div style={{ marginBottom: '10px' }}> Product Comparison </div>
      <div style={{ width: '100%', minWidth: '300px' }}>
        <Bar
          label={'Texture'}
          percent={'48%'}
          secondPercent={'31%'}
          diff={'+17%'}
        />
        <Bar
          label={'No MSG'}
          percent={'22%'}
          secondPercent={'13%'}
          diff={'+9%'}
        />

        <Bar
          label={'Flavor'}
          percent={'34%'}
          secondPercent={'48%'}
          diff={'-14%'}
        />
        <Bar
          label={'Vegan'}
          percent={'14%'}
          secondPercent={'19%'}
          diff={'-5%'}
        />
      </div>
      <div style={{ marginTop: '24px', fontSize: '12px' }}>
        % of reviews that mention attribute for product and competitor
      </div>
    </div>
  );
};

export default HomepageChart;
