import styled from 'styled-components';
import { FadeIn } from 'shared/styles';

export const ContentWindow = styled(FadeIn)`
  overflow-x: hidden;
  flex-grow: 1;
  display: flex;
  background: white;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 960px;
`;

export const ProductRankingItem = styled.div`
  position: relative;
  margin-left: 16px;
  cursor: pointer;
`;

export const SortIndex = styled.div`
  display: none;
`;

export const Badge = styled.div`
  position: absolute;
  top: -6px;
  left: -6px;
  background: #eca32b;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  color: white;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
`;

export const BottomOverlay = styled.div`
  background-color: white;
  width: 100%;
  height: 124px;
  max-height: 170px;
  position: sticky;
  bottom: 0;
  opacity: 1;
  box-shadow: 0px -3px 4px #eee;
`;

export const CenteredBox = styled.div`
  opacity: 1;
  width: 100%;
  max-width: 980px;
  align-content: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
`;

export const ProductRankingPreviewContainer = styled.div`
  max-width: 100%;
  margin: auto;
  display: flex;
  height: 100px;
  align-items: center;
  overflow-y: scroll;
`;

export const ProductRankingMatches = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #0f1617;
  margin-left: 16px;
  padding-top: 0.5rem;
`;

export const ProductRankingMatchText = styled.span`
  font-weight: normal;
  padding-left: 0.5px;
  opacity: 0.6;
`;

export const ImageContainer = styled.div`
  height: 60px;
  width: 60px;
  min-width: 60px;
`;

export const ProductImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const ProgressBarContainer = styled.div`
  position: relative;
`;

export const ProgressBar = styled.div`
  height: 3px;
  margin-top: -2px;
  background-color: #cacaca;
`;

export const ProgressIndicator = styled.div`
  width: 0%;
  height: 3px;
  background-color: rgb(77, 182, 200);
  position: relative;
  top: 1px;
  left: -1px;
`;

export const ProgressText = styled.div`
  margin-top: 4px;
  text-align: right;
  color: #ccc;
`;

export const GuideTitle = styled.div`
  font-size: 27px;
  font-weight: 500;
  color: #0f1617;
  margin-bottom: 8px;
`;

export const GuideIntroText = styled.div`
  color: #666666;
  margin-top: 12px;
  line-height: 1.4;
`;
