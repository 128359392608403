import styled from 'styled-components';

const Box = styled.div`
  display: ${(props) => props.display};
  padding: ${(props) => props.p + 'px'};
  padding-left: ${(props) => props.pl + 'px'};
  padding-right: ${(props) => props.pr + 'px'};
  padding-top: ${(props) => props.pt + 'px'};
  padding-bottom: ${(props) => props.pb + 'px'};
  margin: ${(props) => props.m + 'px'};
  margin-left: ${(props) => props.ml + 'px'};
  margin-right: ${(props) => props.mr + 'px'};
  margin-top: ${(props) => props.mt + 'px'};
  margin-bottom: ${(props) => props.mb + 'px'};
`;

export default Box;
