import React from 'react';
import PropTypes from 'prop-types';
import { MainQuestionText, SubQuestionText } from './styles';
import QuestionFrameV70 from './QuestionFrameV70';
import StandardizedButtons from '../common/StandardizedButtonsV70';

const TextQuestionV70 = ({
  questionText,
  questionSubtext,
  questionAnswered,
  buttonsText,
  contentBefore,
  contentAfter,
  wrapButtons,
}) => {
  const question = (
    <>
      <MainQuestionText>{questionText}</MainQuestionText>
      <SubQuestionText>{questionSubtext}</SubQuestionText>
    </>
  );

  const buttonsComponent = (
    <StandardizedButtons
      buttons={buttonsText}
      questionAnswered={questionAnswered}
      wrapButtons
    />
  );
  return (
    <QuestionFrameV70
      question={question}
      buttons={buttonsComponent}
      contentBefore={contentBefore}
      contentAfter={contentAfter}
    />
  );
};

TextQuestionV70.propTypes = {
  questionText: PropTypes.string.isRequired,
  questionSubtext: PropTypes.string,
  questionAnswered: PropTypes.func,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  contentBefore: PropTypes.any,
  contentAfter: PropTypes.any,
  wrapButtons: PropTypes.bool,
};

export default TextQuestionV70;
