import React, { useState } from 'react';
import styled from 'styled-components';

import { Text } from './Homepage.styles.js';

export const Button = styled.button`
  background: #cce9b6;
  color: rgb(33 156 178);
  border: 1px solid rgb(33 156 178 / 45%);
  box-shadow: 2px 2px 3px -1px;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
  font-weight: normal;
  letter-spacing: 0px;
`;

const HomepageFilterExamples = () => {
  const buttonMap = {
    'Pain points': [
      'I needed this for...',
      'I was looking for something that could...',
      "I've been struggling with...",
      'I was hoping for something that...',
    ],
    Skepticism: [
      "I didn't think it could...",
      "I wasn't sure about...",
      "'I was skeptical of...",
      'I hesitated because...',
    ],
    Outcomes: [
      'It completely changed my...',
      'I could finally...',
      '... started improving',
      "With this, I've managed to...",
    ],
  };

  const [selected, setSelected] = useState(Object.keys(buttonMap)[0]);

  return (
    <>
      <Text> Let AI find the relevant language and aggregate for you</Text>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          marginTop: '36px',
          gap: '48px',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '12px',
            overflow: 'scroll',
            maxWidth: 'calc(100vw - 16px)',
          }}
        >
          {Object.entries(buttonMap).map(([key, value]) => (
            <Button
              key={key}
              onClick={() => setSelected(key)}
              style={{
                backgroundColor: selected === key && '#3f8d49',
                color: selected === key && 'white',
                width: '160px',
              }}
            >
              {key}
            </Button>
          ))}
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '5fr 5fr',
            gap: '20px',
            width: '100%',
          }}
        >
          {selected &&
            buttonMap[selected].map((text) => (
              <div
                key={text}
                style={{
                  borderRadius: '10px',
                  border: '2px dashed #bbb',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  padding: '5px 10px',
                  fontSize: '16px',
                  color: '#555',
                }}
              >
                "{text}"
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default HomepageFilterExamples;
