import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Buttons from '../common/StandardizedButtonsV70';
import QuestionFrameV70 from './QuestionFrameV70';
import SlideInModal from 'shared/components/SlideInModal';
import CommonButton from 'components/common/CommonButton';

import {
  GuideInstructionsContainer,
  MainQuestionText,
  SubQuestionText,
  GuideInstructionsText,
  AttributeText,
} from './styles';

// 'By telling us which features are important, we remove ' +
//   'products that performed poorly according to ' +
//   props.guide.reviews.toLocaleString(navigator.language, {
//     minimumFractionDigits: 0,
//   }) +
//   ' user reviews.'

const GuideInstructions = ({ onClose }) => {
  return (
    <GuideInstructionsContainer>
      <MainQuestionText>How We Help Find The Perfect Product</MainQuestionText>
      <GuideInstructionsText>
        The questions in this guide help us understand your needs. Tell us what
        matters, and we'll eliminate products that performed poorly according to
        customer reviews.
      </GuideInstructionsText>
      <GuideInstructionsText>
        The result? The fastest way to the perfect choice for you!
      </GuideInstructionsText>

      <CommonButton onClick={onClose}> Close </CommonButton>
    </GuideInstructionsContainer>
  );
};

const ConfirmGuideQuestionV70 = ({
  guide,
  productCount,
  questionAnswered,
  contentBefore,
  contentAfter,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleQuestionAnswered = (value) => {
    if (value === 'no') {
      window._tracking &&
        window._tracking.track("Tapped 'how does this work?'");
      setShowModal(true);
    } else {
      questionAnswered(value);
    }
  };

  const question = (
    <>
      <MainQuestionText>
        We found{' '}
        <AttributeText>
          {productCount} {guide.title.toLowerCase()}
          {'. '}
        </AttributeText>
        Ready to narrow down the options?
      </MainQuestionText>
      <SubQuestionText>
        Tell us about yourself, and see which face masks lived up to the hype
        according to the people most like you in{' '}
        {guide.reviews.toLocaleString()} reviews.
      </SubQuestionText>
    </>
  );

  const buttons = (
    <Buttons
      buttons={[
        { text: 'Yes, get matching!', value: 'yes' },
        { text: 'No, how does this work?', value: 'no' },
      ]}
      questionAnswered={handleQuestionAnswered}
      horizontal={true}
    />
  );

  return (
    <>
      <QuestionFrameV70
        question={question}
        buttons={buttons}
        contentBefore={contentBefore}
        contentAfter={contentAfter}
      />
      <SlideInModal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
      >
        <GuideInstructions onClose={() => setShowModal(false)} />
      </SlideInModal>
    </>
  );
};

ConfirmGuideQuestionV70.propTypes = {
  guide: PropTypes.object.isRequired,
  productCount: PropTypes.number.isRequired,
  questionAnswered: PropTypes.func.isRequired,
  contentBefore: PropTypes.any,
  contentAfter: PropTypes.any,
};

export default ConfirmGuideQuestionV70;
