import React from 'react';
import PropTypes from 'prop-types';

import classes from './ProductRankingRow.module.css';

const ProductRankingRowLayout = ({
  products,
  productRenderComponent,
  numProducts,
  selectProduct,
  selectedProduct,
  includeBottomBorder,
  hideScore,
  centered,
}) => {
  if (!products) return null;

  if (numProducts) {
    products = products.slice(0, numProducts);
  }

  let divClass = classes.productRankingRow;
  if (includeBottomBorder) {
    divClass = divClass + ' ' + classes.borderBottom;
  }

  const style = centered ? { justifyContent: 'center' } : {};

  let ProductRenderComponent = productRenderComponent;

  return (
    <div className={divClass} style={style}>
      {products.map((p, index) => (
        <ProductRenderComponent
          key={p.id}
          index={index}
          product={p}
          onClick={selectProduct}
          isSelected={selectedProduct && selectedProduct.id === p.id}
          hideScore={hideScore}
        />
      ))}
    </div>
  );
};

ProductRankingRowLayout.propTypes = {
  products: PropTypes.array.isRequired,

  numProducts: PropTypes.number,
  selectProduct: PropTypes.func,
  selectedProduct: PropTypes.object,
  includeBottomBorder: PropTypes.bool,
};

export default ProductRankingRowLayout;
