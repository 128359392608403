import React from 'react';
import PropTypes from 'prop-types';

import QuestionFrameV70 from './QuestionFrameV70';
import { PriceFilterInstructions } from './styles';

// PriceFilter depends on Rheostat which adds substantially to the bundle
// size and is not required on initial load.
const PriceFilterV70 = React.lazy(() =>
  import(/* webpackPrefetch: true */ '../PriceFilter/PriceFilterV70')
);

class PriceQuestionFollowupV70 extends React.Component {
  static attribute = 'price-range';

  onApplyValues = (state) => {
    let eventProps = {};

    if (
      state.lowerBound !== state.startingLowerBound ||
      state.upperBound !== state.startingUpperBound
    ) {
      if (state.lowerBound !== state.startingLowerBound) {
        eventProps.lowerBound = state.lowerBound;
      }
      if (state.upperBound !== state.startingUpperBound) {
        eventProps.upperBound = state.upperBound;
      }
    }

    this.props.questionAnswered &&
      this.props.questionAnswered({
        minPrice: eventProps.lowerBound,
        maxPrice: eventProps.upperBound,
      });
  };

  onPriceChange = (state) => {
    let prices = this.props.prices;
    let min_price = state.lowerBound;
    let max_price = state.upperBound;
    let count = 0;
    for (let i = 0; i < prices.length; i++) {
      if (prices[i] > min_price && prices[i] < max_price) {
        count += 1;
      }
    }

    this.props.onPriceSliderChange && this.props.onPriceSliderChange(count);
  };

  render() {
    let PriceFilterComponent = PriceFilterV70;
    let priceFilter = (
      <div>
        <PriceFilterInstructions>
          {' '}
          Drag the handles to set your price range{' '}
        </PriceFilterInstructions>
        <React.Suspense fallback={'Loading...'}>
          <PriceFilterComponent
            prices={this.props.prices}
            onChanging={this.onPriceChange}
            onApplyValues={this.onApplyValues}
          />
        </React.Suspense>
      </div>
    );

    return <QuestionFrameV70 question={priceFilter} />;
  }
}

PriceQuestionFollowupV70.propTypes = {
  onPriceSliderChange: PropTypes.func.isRequired,
  prices: PropTypes.array.isRequired,
};

export default PriceQuestionFollowupV70;
