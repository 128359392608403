import React from 'react';
import PropTypes from 'prop-types';

import TextQuestion from './TextQuestionV70';

const AttributeChoiceQuestionV70 = ({
  questionText,
  questionSubtext,
  answers,
  questionAnswered,
  contentBefore,
  contentAfter,
}) => {
  return (
    <TextQuestion
      questionText={questionText}
      questionSubtext={questionSubtext}
      questionAnswered={questionAnswered}
      buttonsText={answers}
      contentBefore={contentBefore}
      contentAfter={contentAfter}
    />
  );
};

const Answer = PropTypes.shape({
  text: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

AttributeChoiceQuestionV70.propTypes = {
  questionText: PropTypes.string.isRequired,
  questionSubtext: PropTypes.string,
  questionAnswered: PropTypes.func,
  answers: PropTypes.arrayOf(Answer).isRequired,
  contentBefore: PropTypes.any,
  contentAfter: PropTypes.any,
};

export default AttributeChoiceQuestionV70;
