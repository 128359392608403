import React from 'react';
import PropTypes from 'prop-types';
import CommonButton from './CommonButton';
import { ButtonsContainer } from './styles';

const StandardizedButtons = ({ buttons, questionAnswered, wrapButtons }) => {
  return (
    <ButtonsContainer wrapButtons={wrapButtons}>
      {buttons.map((button) => (
        <CommonButton
          key={button.text}
          onClick={() => questionAnswered(button.value)}
          inline={wrapButtons}
        >
          {button.text}
        </CommonButton>
      ))}
    </ButtonsContainer>
  );
};

const Answer = PropTypes.shape({
  text: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

StandardizedButtons.propTypes = {
  buttons: PropTypes.arrayOf(Answer).isRequired,
  questionAnswered: PropTypes.func.isRequired,
};

export default StandardizedButtons;
