import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './shared/routes';
import AppProvider from './AppProvider';
// import { GlobalStyle } from 'shared/styles';

const App = () => {
  const query = window.location.search;

  const queryParams = query.split('?');
  if (queryParams.length > 1) {
    const params = queryParams[1].split('&');
    params.forEach(
      (p) => (document.cookie = p + '; path=/; max-age=31536000;')
    );
  }

  return (
    <AppProvider>
      <Router>
        <Routes />
      </Router>
    </AppProvider>
  );
};

export default App;
