import styled from 'styled-components';

import { PageContent } from 'shared/styles';

export const LandingPageContainer = styled(PageContent)`
  background: #555;
  min-height: 100vh;
`;

export const LandingPageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 2rem;
  border-radius: 16px;
  border: 1px solid #ccc;
  padding: 2rem;
  text-align: center;
  background: white;
`;

export const GuideIntroTitle = styled.div`
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
`;

export const GuideIntroText = styled.div`
  line-height: 1.4;
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
`;

export const KeyStatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: #efefefb8;
  padding: 1rem 1.5rem;
  border-radius: 1pc;
`;

export const ButtonContainer = styled.div`
  width: 75%;
  max-width: 250px;
  position: sticky;
  top: 20px;
  margin: auto;
  margin-top: 2rem;
`;

export const SectionContent = styled.div`
  margin-bottom: 1.5rem;
`;
