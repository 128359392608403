import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Isotope from 'isotope-layout';
import 'isotope-horizontal';

const ProductRankingAnimation = ({ children }) => {
  const [isotope, setIsotope] = useState(null);

  const nodeRef = React.useRef();

  useEffect(() => {
    setIsotope(
      new Isotope(nodeRef.current, {
        itemSelector: '.productRankingItem',
        layoutMode: 'horiz',
        horiz: {
          verticalAlignment: 0.5,
        },
        getSortData: {
          index: '.prtIndex parseInt',
        },
        sortBy: 'index',
        sortAscending: false,
      })
    );
  }, []);

  useEffect(() => {
    if (isotope) {
      isotope.reloadItems();
      isotope.arrange();
    }
  }, [children, isotope]);

  return (
    <div ref={nodeRef} style={{ display: 'flex' }}>
      {children}
    </div>
  );
};

ProductRankingAnimation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default React.memo(ProductRankingAnimation);
