import React from 'react';
import styled from 'styled-components';

export const GreenCheckIcon = styled.img.attrs(() => ({
  src: '/static/images/check.svg',
  alt: 'check',
}))`
  height: 12px;
  width: 12px;
`;

export const RedXIcon = () => (
  <img height='12' width='12' src='/static/images/x.svg' alt='check' />
);

export const DownCaret = () => (
  <img height='12' width='12' src='/static/images/down-caret.svg' alt='check' />
);

export const UpCaret = () => (
  <img height='12' width='12' src='/static/images/up-caret.svg' alt='check' />
);
