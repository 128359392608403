import styled from 'styled-components';

export const GuideIntroText = styled.div`
  color: rgb(85, 85, 85);
  margin: 1.5rem 0 1rem 0;
  line-height: 1.4;
  background: #f2f2f2;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  max-width: 450px;
`;

export const EmphasizedText = styled.span`
  font-weight: bold;
  color: #0f1617;
  line-height: 1.3;
`;
