import React from 'react';
import { Loader } from '../Loader';
import Modal from 'shared/components/Modal';

export default function DisplayOnceLoaded(props) {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <React.Fragment>
      {props.isLoading ? (
        <Modal
          style={customStyles}
          isOpen={props.isLoading}
          onRequestClose={() => {}}
        >
          <div style={{ textAlign: 'center' }}>
            <Loader isVisible={true} text={props.text || 'Loading...'} />
          </div>
        </Modal>
      ) : (
        props.children
      )}
    </React.Fragment>
  );
}
