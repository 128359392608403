import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { API } from '../components/api';
import {
  isAttributeQuestion,
  getAttributeCount,
  getSelectedAttributeCount,
} from '../components/ProductDetailPage/constants';
import GuideContext from '../shared/context/guide/GuideContext';
import { GuideActions } from '../shared/context/guide/store';
const api = new API();

const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export const withProductService = (Component) => (props) => {
  const { match, history, location, answers, guideData, priceRange } = props;
  const {
    params: { productId },
  } = match;
  const query = useQueryParams();
  const includeProduct = query.get('includeProduct');
  const { dispatch, state } = useContext(GuideContext);
  const [productResults, setProductResults] = useState({});
  const [matchedCount, setMatchedCount] = useState(null);

  const getProductResults = (canApplyState) => {
    api.getProductResults(
      guideData.guide.id,
      Number(productId),
      answers,
      priceRange.minPrice,
      priceRange.maxPrice,
      answers.length,
      state.startingProduct ? state.startingProduct : null,
      (data) => {
        // Allows to check for unmounted components and escape if so. This seems
        // to happen during questions if you answer quickly and server response
        // is slow
        if (canApplyState && !canApplyState()) {
          return;
        }

        let product = productId
          ? data.products.find((p) => p.id === Number(productId))
          : data.products[0];

        if (!product) {
          navigateToProductPage(null);
        }

        const attribMatchCount = Math.max(
          ...data.products.map((p) => parseInt(p.matched))
        );

        const selectedAttributeCount = getSelectedAttributeCount(answers);

        setMatchedCount(data.matchedCount);
        setProductResults({
          ...data,
          attribMatchCount,
          selectedAttributeCount,
          product,
        });
      }
    );
  };

  const goToGuideStart = (productId) => {
    let url = `/on/${guideData.guide.title.toLowerCase().replace(/ /g, '-')}`;
    url += productId ? `?includeProduct=${productId}` : '';
    history.push(url);
  };

  const onSelectProduct = (product) => {
    if (product !== productResults.product) {
      // We don't need to set product immediately, but it's good to do
      // so because it'll immediately change the product image on product detail
      // page instead of showing the old product until API response completes
      setProductResults({ ...productResults, product: null });
    }

    navigateToProductPage(product.id);
  };

  // TODO: Consider whether this should be in GuideRoutes or withProductService
  const navigateToProductPage = (productId) => {
    const paths = location.pathname.split('/');
    const productIndex = paths.indexOf(
      paths.find((item) => item === 'product')
    );
    let newPaths = [...paths];
    if (productId) {
      newPaths.splice(productIndex + 1, 1, productId);
    } else {
      newPaths.splice(productIndex + 1, 1);
    }
    history.push(newPaths.join('/'));
  };

  useEffect(() => {
    if (guideData && !matchedCount) {
      setMatchedCount(guideData.matchedCount);
    }
  }, [guideData, matchedCount]);

  useEffect(() => {
    if (includeProduct)
      dispatch(GuideActions.setStartingProduct(Number(includeProduct)));
  }, [includeProduct, dispatch]);

  // TODO: Review api.getProductResults function inputs and response.
  // We should be able to simplify the response so that function can be
  // static and removed from the dependency array.
  useEffect(() => {
    let mounted = true;
    if (
      // Make sure at least 1 Attribute-based question has been answered
      getAttributeCount(answers.map((a) => a.question)) > 0 &&
      // Make that guide response has been set
      (guideData.guide.id || productId)
    ) {
      getProductResults(() => mounted);
    }

    return () => (mounted = false);

    // TODO: priceRange is a dependency in getProductResults, but when price
    // range is changed, it also changes answers causing 2 product calls. For
    // now, we are only triggering on answers change, but this seems like it may
    // break in the future if we don't figure out how to fix.
    // eslint-disable-next-line
  }, [guideData, answers, productId]);

  return (
    <Component
      {...props}
      {...productResults}
      attributes={
        answers &&
        answers.filter((answer) => isAttributeQuestion(answer.question))
      }
      matchedCount={matchedCount}
      onSelectProduct={onSelectProduct}
      updateMatchCount={setMatchedCount}
      getProductResults={getProductResults}
      goToGuideStart={goToGuideStart}
    />
  );
};
