import React from 'react';

// TODO: Combine StarRating and StarViz
const StarRating = (props) => {
  let full_stars = Math.floor(props.rating);
  let half_star = props.rating - full_stars > 0.25;

  let color = props.onColor || 'gold';
  let offColor = props.offColor || 'gray';
  let starStyle = {
    color: color,
    width: '1em',
    display: 'inline-block',
  };
  let offStarStyle = {
    content: parseInt('2605', 8),
    color: offColor,
    width: '1em',
    display: 'inline-block',
  };

  let halfStarStyle = {
    content: parseInt('2605', 8),
    color: color,
    position: 'absolute',
    marginLeft: '-1em',
    width: '0.5em',
    overflow: 'hidden',
  };

  var stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < full_stars) {
      stars.push(
        <span key={i} style={starStyle}>
          ★
        </span>
      );
    } else if (half_star) {
      stars.push(
        <span key={i} style={offStarStyle}>
          ★
        </span>
      );
      stars.push(
        <span key={i + '.5'} style={halfStarStyle}>
          ★
        </span>
      );
      half_star = false;
    } else {
      stars.push(
        <span key={i} style={offStarStyle}>
          ★
        </span>
      );
    }
  }

  return <div> {stars}</div>;
};

export default StarRating;
