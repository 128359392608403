import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import SiteHeader from 'shared/components/SiteHeader';

import Homepage from 'pages/Homepage/Homepage';
import AboutPage from 'pages/AboutPage/AboutPage'
import SavedGuide from '../../containers/SavedGuide';

import Guide from '../../containers/Guide';

const Routes = () => {
  const { pathname, search } = useLocation();

  return (
    <>
      <SiteHeader />

      <Switch>
        <Route exact path='/' component={Homepage} />
        <Route exact path='/about' component={AboutPage} />
        <Redirect
          from='/:url*(/+)'
          to={pathname.replace(/\/+$/, '') + search}
        />
        <Route path='/save/:saveId' component={SavedGuide} />
        <Route
          path='/on/:guideName/for/:requestedPrimaryAttributeName'
          component={Guide}
        />
        <Route path='/on/:guideName' component={Guide} />
      </Switch>
    </>
  );
};

export default Routes;
