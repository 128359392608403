import React, { useState } from 'react';
import BaseInputField from 'shared/components/BaseInputField';

import { LookupNotFoundTitle, LookupNotFoundText } from './styles';

const LookupNotFound = ({ term, onSubmitEmail }) => {
  const [emailAddress, setEmailAddress] = useState(null);

  return (
    <>
      <LookupNotFoundTitle> No results found for {term} </LookupNotFoundTitle>
      <LookupNotFoundText>
        We've forwarded your request for {term} to one of our brand concierges.
        Enter your email address so we can send you the results.
      </LookupNotFoundText>

      <BaseInputField
        onSubmit={() => onSubmitEmail(emailAddress)}
        onChange={(e) => setEmailAddress(e.target.value)}
        placeholder={'Enter your email'}
      />
    </>
  );
};

export default LookupNotFound;
