import React from 'react';

import ProductInformation from 'components/ProductDetailPage/layout/ProductInformation';
import ProductBuyLinks from './ProductBuyLinks';
import DetailSection from './DetailSection';

import AttributeSummary from './AttributeSummary';
import LookupAttribute from './LookupAttribute';

import { Text } from './styles';
import { PagePadding } from 'pages/ProductDetailPage/styles';

const ProductDetailPage = ({
  product,
  products,
  guideData,
  goToGuideStart,
  onSelectProduct,
  onBuyButton,
  answers,
}) => {
  return (
    <PagePadding>
      <div style={{ marginBottom: 40 }}>
        <ProductInformation
          product={product}
          withRating
          fullSize
          additional={
            <ProductBuyLinks
              sources={product.sources}
              onClick={({ sourceUrl }) => {
                onBuyButton({ product, sourceUrl });
              }}
            />
          }
        />
      </div>

      <DetailSection
        title={'Why People Choose This'}
        subtitle={
          'Reviewers mention these more often compared to other face masks'
        }
      >
        <Text>
          {product &&
            product.ratings
              .filter((r) => r.rating.unique)
              .map((r, index) => (
                <AttributeSummary
                  key={r.id}
                  attribute={r}
                  positiveSnippet={product.snippets[r.id].positive[0]}
                  negativeSnippet={product.snippets[r.id].negative[0]}
                  percentPositive={product.snippets[r.id].percent}
                  mentions={
                    product.snippets[r.id].positiveCount +
                    product.snippets[r.id].negativeCount
                  }
                  matched={r.rating.score}
                  startOpen={index === 0}
                />
              ))}
        </Text>
      </DetailSection>

      <DetailSection
        title={'Potential Concerns'}
        subtitle={'Reviewers mention these most often in negative reviews'}
      >
        {product &&
          product.ratings
            .filter((r) => r.rating.negative)
            .map((r) => (
              <AttributeSummary
                key={r.id}
                attribute={r}
                positiveSnippet={product.snippets[r.id].positive[0]}
                negativeSnippet={product.snippets[r.id].negative[0]}
                percentPositive={product.snippets[r.id].percent}
                mentions={
                  product.snippets[r.id].positiveCount +
                  product.snippets[r.id].negativeCount
                }
                matched={r.rating.score}
              />
            ))}
      </DetailSection>

      <DetailSection
        title={'Is it good for ______________?'}
        subtitle={'What’s important to you? What should it do well?'}
        hideBorder
      >
        <LookupAttribute guide={guideData.guide} productId={product.id} />

        {product &&
          product.ratings
            .filter((r) => r.rating.fromAnswer)
            .map((r) => (
              <AttributeSummary
                key={r.id}
                attribute={r}
                positiveSnippet={product.snippets[r.id].positive[0]}
                negativeSnippet={product.snippets[r.id].negative[0]}
                percentPositive={product.snippets[r.id].percent}
                mentions={
                  product.snippets[r.id].positiveCount +
                  product.snippets[r.id].negativeCount
                }
                matched={r.rating.score}
              />
            ))}
      </DetailSection>

      <DetailSection
        title={'Compared to...'}
        subtitle={'How it stacks up with other options'}
        hideBorder
      >
        {product.other_options.map((p) => (
          <div key={p.id} style={{ marginBottom: '24px' }}>
            <ProductInformation
              product={p}
              onClick={() => onSelectProduct(p)}
              additional={
                <div>
                  <div style={{ fontWeight: 500, color: '#666' }}>
                    Consider if you're looking for:
                  </div>
                  {p.ratings
                    .filter((r) => r.rating.unique && !r.rating.fromAnswer)
                    .slice(0, 3)
                    .map((r) => r.name)
                    .join(', ')}
                </div>
              }
            />
          </div>
        ))}
      </DetailSection>
    </PagePadding>
  );
};

// Move this up one level
export default ProductDetailPage;
