import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ReactGuide from '../../components/ReactGuide/ReactGuide';
import GuideProvider from '../context/guide/GuideProvider';

import ProductResultsContainer from 'containers/ProductResultsContainer';
import ProductDetailsContainer from 'containers/ProductDetailsContainer';

const GuideRoutes = ({ guideData, versionConfig }) => {
  // TODO: Add versionConfig for question component and retrieve it here for
  // new question page types
  const QuestionComponent = ReactGuide;

  const routes = [
    {
      path: '/on/:guideName/product/(.*-)?:productId',
      component: ProductDetailsContainer,
    },
    {
      path: '/on/:guideName/product/:productId',
      component: ProductDetailsContainer,
    },
    {
      path:
        '/on/:guideName/for/:requestedPrimaryAttributeName/answers/:urlAnswers*/product/:productId',
      component: ProductDetailsContainer,
    },
    {
      path: '/on/:guideName/answers/:urlAnswers*/product/:productId',
      component: ProductDetailsContainer,
    },
    {
      path:
        '/on/:guideName/for/:requestedPrimaryAttributeName/answers/:urlAnswers*/product',
      component: ProductResultsContainer,
    },
    {
      path: '/on/:guideName/answers/:urlAnswers*/product',
      component: ProductResultsContainer,
    },
    {
      // NOTE: If we separate these paths each to its own line, the react-router
      // remounts the component when the key changes which causes the
      // withAnswerService to lose state momentarily until it resets itself
      path: [
        '/on/:guideName/(for)?/:requestedPrimaryAttributeName?/answers/:urlAnswers*',
        '/on/:guideName/(for)?/:requestedPrimaryAttributeName?',
      ],
      component: QuestionComponent,
    },
  ];

  return (
    <GuideProvider>
      <Switch>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            render={(props) => (
              <route.component
                {...props}
                guideData={guideData}
                versionConfig={versionConfig}
              />
            )}
          />
        ))}
      </Switch>
    </GuideProvider>
  );
};

export default GuideRoutes;
