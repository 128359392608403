import React from 'react';
import PropTypes from 'prop-types';

import classes from './modal.module.css';
import Modal from './Modal';

const SlideInModal = ({
  isOpen,
  onRequestClose,
  className,
  style,
  children,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={{
        base: classes.SlideInModal__Overlay,
        afterOpen: classes.SlideInModal__OverlayAfterOpen,
        beforeClose: classes.SlideInModal__OverlayBeforeClose,
      }}
      shouldReturnFocusAfterClose={false}
      style={style}
      closeTimeoutMS={400}
    >
      {children}
    </Modal>
  );
};

SlideInModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

export default SlideInModal;
