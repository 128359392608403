import styled from 'styled-components';

const CommonButton = styled.div`
  border: 1px solid #4eb6c9;
  box-sizing: border-box;
  padding: 8px 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  color: #0f1617;
  text-align: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
  display: ${({ inline }) => (inline ? 'inline-block' : 'flex')};
  flex-grow: 1;
  flex-basis: 1px;
  &:hover {
    background: #4eb6c9;
    border: 1px solid #4eb6c9;
    color: white;
  }
`;

export default CommonButton;
