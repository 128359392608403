import React, { useReducer } from 'react';
import AppContext from './AppContext';
import { appReducer, appInitialState } from './store/reducer';

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, appInitialState);
  return (
    <AppContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
