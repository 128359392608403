import React from 'react';
import PropTypes from 'prop-types';
import StarRating from 'shared/components/StarRating';
import StarViz from 'shared/components/StarViz';

import {
  ProductRatingContainer,
  ProductRatingHeader,
  ProductRatingVisualizationContainer,
  ProductInformationContainer,
  ImageContainerFullSize,
  ProductImg,
  ProductBrand,
  ProductTitle,
  ProductRating,
  ProductRatingData,
  ProductPrice,
  ProductInformationAdditonalContainer,
} from './ProductInformation.styles.js';

import { Divider } from 'pages/ProductResultsPage/ProductResultsPageV70/styles.js';
import { ProductSummaryHeaderText } from './ProductResultsPageV73.styles.js';

const ProductInformation = ({
  product,
  withPrice,
  primaryAttributes,
  additional,
  onClick,
}) => {
  return (
    <ProductRatingContainer onClick={onClick}>
      <ProductRatingHeader>
        <ImageContainerFullSize>
          <ProductImg alt={product.title} src={product.image_url} />
        </ImageContainerFullSize>
        <ProductInformationContainer>
          <ProductBrand>{product.brand}</ProductBrand>
          <ProductTitle>{product.title}</ProductTitle>
          {withPrice && product.formatted_price && (
            <ProductPrice> {product.formatted_price}</ProductPrice>
          )}
          <ProductRating small>
            <StarViz
              starRating={product.average_rating}
              numberReviews={product.number_of_reviews}
            />
          </ProductRating>
        </ProductInformationContainer>
      </ProductRatingHeader>

      <Divider />
      {product.average_rating && (
        <ProductRatingVisualizationContainer>
          <ProductSummaryHeaderText smallMargin>
            {primaryAttributes.join(' & ')} Reviews
          </ProductSummaryHeaderText>
          <ProductRating>
            <StarRating rating={product.primaryAttributes.averageRating} />
            <ProductRatingData>
              {product.primaryAttributes.averageRating} (
              {product.primaryAttributes.numberOfReviews.toLocaleString()}{' '}
              reviews)
            </ProductRatingData>
          </ProductRating>
        </ProductRatingVisualizationContainer>
      )}
      <ProductInformationAdditonalContainer>
        {additional}
      </ProductInformationAdditonalContainer>
    </ProductRatingContainer>
  );
};

ProductInformation.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    average_rating: PropTypes.number.isRequired,
    number_of_reviews: PropTypes.number.isRequired,
  }).isRequired,
  withRating: PropTypes.bool,
  withPrice: PropTypes.bool,
};

export default ProductInformation;
