import { SET_VERSION_CONFIG } from './actions';

export const appInitialState = {};

export const appReducer = (state = appInitialState, action) => {
  switch (action.type) {
    case SET_VERSION_CONFIG: {
      return { ...state, versionConfig: action.payload };
    }
    default: {
      return state;
    }
  }
};
