import React from 'react';
import PropTypes from 'prop-types';

import BuyButton from './BuyButton';
import { ProductBuyLinksContainer } from './styles';

const ProductBuyLinks = ({ sources, onClick }) => {
  return (
    <ProductBuyLinksContainer>
      {sources.map((source) => {
        return (
          <BuyButton
            key={source.url}
            price={source.price}
            url={source.url}
            text={source.name}
            onClick={() => onClick({ sourceUrl: source.url })}
          />
        );
      })}
    </ProductBuyLinksContainer>
  );
};

ProductBuyLinks.propTypes = {
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  onClick: PropTypes.func.isRequired,
};

export default ProductBuyLinks;
