import styled from 'styled-components';

const PrimaryButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #ffffff;
  background: ${(props) => (props.orange ? 'rgb(235 163 44)' : '#4eb6c9')};
  border: 0px;
  border-radius: 0px;
  text-transform: initial;
  width: 100%;
  height: 40px;
`;

export default PrimaryButton;
