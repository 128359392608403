import React from 'react';
import PropTypes from 'prop-types';
import { PagePadding } from 'shared/styles';

const QuestionFrameV70 = ({
  onClick,
  contentBefore,
  contentAfter,
  question,
  buttons,
}) => {
  // TODO: slide handles both CSS formatting and some kind of slide management
  // indexing
  return (
    <div className='slide' onClick={onClick}>
      {contentBefore}

      <PagePadding>
        <div
          style={{
            minHeight: '300px',
            overflow: 'visible',
            paddingBottom: '4rem',
          }}
        >
          {question}

          {buttons && buttons}
        </div>
      </PagePadding>

      {contentAfter}
    </div>
  );
};

QuestionFrameV70.propTypes = {
  question: PropTypes.any.isRequired,
  buttons: PropTypes.any,
  contentBefore: PropTypes.any,
  contentAfter: PropTypes.any,
  onClick: PropTypes.func,
};

export default QuestionFrameV70;
