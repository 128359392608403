import React from 'react';
import PropTypes from 'prop-types';
import TextQuestionV70 from './TextQuestionV70';

const PriceQuestionV70 = ({
  prices,
  questionAnswered,
  contentBefore,
  contentAfter,
}) => {
  const maxPrice = Math.ceil(Math.max(...prices));
  const minPrice = Math.floor(Math.min(...prices.filter((p) => p)));

  return (
    <TextQuestionV70
      questionText={'Do you have a price range in mind?'}
      buttonsText={[
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ]}
      questionAnswered={questionAnswered}
      questionSubtext={
        'Models typically range from $' + minPrice + ' to $' + maxPrice
      }
      contentBefore={contentBefore}
      contentAfter={contentAfter}
    />
  );
};

PriceQuestionV70.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.number).isRequired,
  questionAnswered: PropTypes.func.isRequired,
  contentBefore: PropTypes.any,
  contentAfter: PropTypes.any,
};

export default PriceQuestionV70;
