import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { API } from '../components/api';

const api = new API();

const strToUrl = (str) => str.toLowerCase().replace(' ', '-');

const SavedGuide = ({ history, match }) => {
  const {
    params: { saveId },
  } = match;

  const buildUrl = (data) => {
    const { guide, answers, product } = data;
    let url = `/on/${strToUrl(guide.name)}`;
    if (answers) {
      url += '/answers';
      answers.forEach((answer) => {
        url += `/${strToUrl(answer.questionId)}.${answer.response}`;
      });
    }
    if (product) {
      url += `/product/${product.id}`;
    }
    return url;
  };

  useEffect(() => {
    api.getSavedGuide(saveId, (data) => {
      const redirectUrl = buildUrl(data);
      history.push(redirectUrl);
    });
  }, [saveId, history]);

  return <></>;
};

export default withRouter(SavedGuide);
