import React, { useState } from 'react';

import { API } from 'components/api';
import SearchBar from 'components/autosuggest/SearchBar';
import AttributeSummary from './AttributeSummary';

import LookupNotFound from './LookupNotFound';

import { Loader } from 'components/Loader';

const api = new API();

// TODO: productId, guide should get filled at higher level
const LookupAttribute = ({ productId, guide }) => {
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState({});
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);

  const [showLookupNotFound, setLookupNotFound] = useState(false);

  const [rating, setRating] = useState(null);
  const [product, setProduct] = useState(null);

  const renderSuggestion = (suggestion) => (
    <div> {suggestion.questionData.attributeName} </div>
  );

  return (
    <div style={{ position: 'relative' }}>
      <SearchBar
        placeholderText={'Search for...'}
        value={search}
        onChange={(e, { newValue }) => {
          setSearch(newValue);
          setLookupNotFound(false);
        }}
        onSubmit={(value) => {
          // No match
          setLookupNotFound(true);
          window._tracking.lookupAttribute(
            {
              name: value,
            },
            productId
          );
        }}
        suggestions={suggestions[search] || []}
        onSuggestionsFetchRequested={({ value }) => {
          api.getAttributes(guide.id, value, (data) => {
            setSuggestions((prevState) => ({
              ...prevState,
              [value]: data.results,
            }));
          });
        }}
        onSuggestionsClearRequested={() => {
          setSuggestions([]);
        }}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={(event, { suggestion }) => {
          setSelectedSuggestion(suggestion);

          const answer = {
            ...suggestion,
            question: suggestion,
            response: 'very',
          };
          setProduct(null);
          setRating(null);
          setSearch('');

          window._tracking.lookupAttribute(
            {
              name: suggestion.questionData.attributeName,
              id: suggestion.questionData.attributeId,
            },
            productId
          );

          api.getProductResults(
            guide.id,
            productId,
            [answer],
            null,
            null,
            null,
            null,
            (data) => {
              setRating(
                data.products[0].ratings.filter((r) => r.rating.fromAnswer)[0]
              );
              setProduct(data.products[0]);
            }
          );
        }}
        getSuggestionValue={(suggestion) =>
          suggestion.questionData.attributeName
        }
      />
      {selectedSuggestion && !product && (
        <div>
          <Loader isVisible={true} scale={0.4} />
        </div>
      )}
      {product && rating && (
        <AttributeSummary
          attribute={rating}
          positiveSnippet={
            product.snippets[rating.id] &&
            product.snippets[rating.id].positive[0]
          }
          negativeSnippet={
            product.snippets[rating.id] &&
            product.snippets[rating.id].negative[0]
          }
          percentPositive={product.snippets[rating.id].percent}
          mentions={
            product.snippets[rating.id].positiveCount +
            product.snippets[rating.id].negativeCount
          }
          matched={rating.rating.score}
          startOpen={true}
        />
      )}
      {showLookupNotFound && (
        <LookupNotFound
          term={search}
          onSubmitEmail={(email) => {
            api.postData('/api/signup/', {
              email: email,
              source: '[' + search + '] on ' + productId,
            });
          }}
        />
      )}
    </div>
  );
};

export default LookupAttribute;
