import styled from 'styled-components';

export const SecondaryTitle = styled.div`
  font-weight: bold;
  font-size: 2.2rem;
  margin-bottom: 2rem;
`;

export const PageCopy = styled.div`
  margin-top: 3.5rem;
  margin-bottom: 10rem;
`;

export const Paragraph = styled.p`
  font-size: 2rem;
`;
