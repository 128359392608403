import React from 'react';
import PropTypes from 'prop-types';
import GuideLayout from './GuideLayout';

import { API } from 'components/api';

import { withAnswerService } from '../../services/withAnswerService';
import { withProductService } from '../../services/withProductService';
import { withRouter } from 'react-router-dom';

const api = new API();

class ReactGuide extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleAnswered = this.handleAnswered.bind(this);
    this.guideLayout = React.createRef();
  }

  // TODO: Fix currTime loading. Needs to be written to the page.
  componentDidMount() {
    if (this.props.answers && this.props.answers.length === 0) {
      const {
        guideData: { primaryAttribute },
      } = this.props;

      window._tracking.startGuide({
        specificUse: primaryAttribute && primaryAttribute.name,
        specificUseId: primaryAttribute && primaryAttribute.id,
        loadTime: window.currTime ? Date.now() - window.currTime : null,
      });
    }
  }

  getAdditionalContent(answers) {
    if (!answers || (answers && answers.length === 0)) return {};
    const {
      guideData: { questions },
    } = this.props;
    const lastAnswer = answers[answers.length - 1];
    const currentQuestion = questions.find(
      (item) => item.questionId === lastAnswer.questionId
    );
    const currentQuestionIndex = questions.indexOf(currentQuestion);
    const nextQuestion =
      currentQuestionIndex === questions.length - 1
        ? currentQuestion
        : questions[currentQuestionIndex + 1];

    return nextQuestion && nextQuestion.additionalContent
      ? nextQuestion.additionalContent
      : {};
  }

  handleAnswered({ question, response, index, isLastQuestion, extraProps }) {
    this.props.addAnswerToUrl({ ...question, response, isLastQuestion });

    if (question.questionData && question.questionData.endpoint && response) {
      api.postData(question.questionData.endpoint, {
        questionId: question.questionId,
        response: response,
        quiz_id: this.props.guideData.guide.id,
        answers: api.serializeAnswers(this.props.answers),
      });
    }

    if (question.questionId === 'price-range') {
      window._tracking.setPriceFilter({
        lowerBound: response.minPrice,
        upperBound: response.maxPrice,
      });
    } else {
      let attributeNames =
        question.questionData &&
        question.questionData.attributes &&
        question.questionData.attributes
          .map((a) => a.attributeName)
          .filter((a) => a);

      if (!attributeNames || !attributeNames.length > 0) {
        attributeNames = question.questionId;
      }
      window._tracking.answerQuestion(
        question.questionId,
        question.questionType,
        attributeNames,
        response,
        index,
        extraProps
      );
    }
  }

  render() {
    return (
      <GuideLayout
        guideData={this.props.guideData}
        answers={this.props.answers}
        products={this.props.products || this.props.guideData.products}
        matchedCount={this.props.matchedCount}
        attributeCount={this.props.attributeCount}
        matchedAttribCount={this.props.attribMatchCount}
        {...this.getAdditionalContent(this.props.answers)}
        handleAnswered={this.handleAnswered}
        updateMatchCount={this.props.updateMatchCount}
        navigateToProductResults={this.props.navigateToProductResults}
      />
    );
  }
}

ReactGuide.propTypes = {
  guideData: PropTypes.object.isRequired,
  answers: PropTypes.array.isRequired,
};

ReactGuide.defaultProps = {
  answers: [],
  matchedAttribCount: 0,
};

export default withAnswerService(withProductService(withRouter(ReactGuide)));
