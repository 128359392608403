import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { PageContent } from 'shared/styles';

import { withAnswerService } from '../services/withAnswerService';
import { withProductService } from '../services/withProductService';

import DisplayOnceLoaded from 'components/common/DisplayOnceLoaded';

const ProductResultsContainer = (props) => {
  useEffect(() => {
    window._tracking.viewedProductResultsPage();
  }, []);

  const versionConfig = props.versionConfig;
  return (
    <PageContent>
      <DisplayOnceLoaded
        isLoading={!(versionConfig && props.products)}
        text={'Finding matches...'}
      >
        {props.products && <versionConfig.productResultsPage {...props} />}
      </DisplayOnceLoaded>
    </PageContent>
  );
};

ProductResultsContainer.propTypes = {
  guideData: PropTypes.object.isRequired,
  products: PropTypes.array,
  versionConfig: PropTypes.object.isRequired,
};

export default withAnswerService(withProductService(ProductResultsContainer));
