import styled from 'styled-components';

export const Slide = styled.div`
  width: 100vw;
  min-height: 85vh;
  flex-shrink: 0;
  padding-top: 4rem;
`;

export const MainQuestionText = styled.div`
  font-weight: 500;
  font-size: 32px;
  color: #0f1617;
  line-height: 1.3;
  letter-spacing: -0.2px;
  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

export const ShortenedAttributeIntro = styled.div`
  font-size: 20px;
  @media (max-width: 480px) {
    font-size: 16px;
    margin-bottom: 4px;
  }
`;

export const SubQuestionText = styled.div`
  color: #0f1617;
  font-weight: normal;
  font-size: 16px;
  color: #666666;
  line-height: 1.4;
  margin-top: 8px;
  letter-spacing: -0.2px;
`;

export const AttributeText = styled.span`
  font-weight: 500;
  color: #eba32a;
  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

export const PriceFilterInstructions = styled.div`
  margin-top: -16px;
  margin-bottom: 32px;
`;

export const InputFieldContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const CancelText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: -0.2px;
  cursor: pointer;

  color: #4db6c8;
`;

export const GuideInstructionsContainer = styled.div`
  padding: 2.5rem 2rem 2rem 2rem;
`;
export const GuideInstructionsText = styled(SubQuestionText)`
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1.6rem;
  color: #555;
`;
