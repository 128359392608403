import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  color: #0f1617;
  padding: 24px 16px;
`;

export const BottomBorder = styled.div`
  border-bottom: 1px solid #d8d8d8;
`;

export const DetailSectionContainer = styled.div`
  margin-bottom: 32px;
`;

export const DetailSectionTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20.8px;
  letter-spacing: -0.2px;
  color: #0f1617;
  margin-bottom: 4px;
`;

export const DetailSectionSubtitle = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: -0.2px;
  margin-bottom: 16px;
  color: #666666;
`;

export const DetailSectionBody = styled.div`
  margin-top: 16px;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: -0.2px;
`;

export const ProductBuyLinksContainer = styled.div`
  margin-top: 16px;
`;

export const BuyButtonStyle = styled.div`
  border: 2px solid #4eb6c9;
  color: #4eb6c9;
  font-size: 16px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.2px;
  border-radius: 4px;
  padding: 4px 16px;
  cursor: pointer;
  margin-bottom: 8px;
  margin-top: 8px;
`;

export const BuyButtonSpacer = styled.span`
  margin-left: 4px;
  margin-right: 8px;
`;

export const BuyPrice = styled.span`
  color: #0f1617;
`;

export const AttributeSnippetPaddingBreaker = styled.div`
  margin-left: -16px;
  margin-right: -16px;
`;

export const AttributeSnippetScroll = styled.div`
  display: flex;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;

  padding-top: 12px;

  &:after {
    content: '';
  }
`;

export const SnippetRow = styled.div`
  margin-bottom: 16px;
  padding-top: 4px;
  padding-left: 4px;
  scroll-snap-align: start;
`;

export const AttributeSummarySubheader = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
  color: #666666;
`;

export const SnippetBox = styled.div`
  width: 300px;
  max-width: 60vw;
  border-radius: 4px;
  box-shadow: 0 0 16px -4px rgba(87, 87, 87, 0.5);
  padding: 16px;
  margin-left: 16px;
`;

export const SnippetText = styled.div`
  margin-bottom: 16px;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  & > em {
    font-weight: bold;
    font-style: normal;
  }
`;

export const SnippetMetadata = styled.div`
  display: flex;
  font-size: 1.2rem;
  flex-wrap: wrap;
  color: #666666;
`;

export const AttributeSummaryContainer = styled.div`
  margin: 16px auto;
`;

export const AttributeSummaryNameRow = styled.div`
  margin-bottom: 8px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const AttributeSummaryName = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  letter-spacing: -0.4px;

  color: #0f1617;
  margin-left: 8px;
`;

export const ToggleState = styled.div`
  align-self: flex-end;
  margin-left: auto;
`;

export const LookupNotFoundTitle = styled.div`
  font-weight: 500;
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const LookupNotFoundText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.20000000298023224px;
  margin-bottom: 16px;
`;
