import React from 'react';
import PropTypes from 'prop-types';

import { SnippetRow, SnippetBox, SnippetText, SnippetMetadata } from './styles';
import StarRating from 'shared/components/StarRating';

const AttributeSnippet = ({ snippet }) => {
  return (
    <SnippetRow>
      <SnippetBox>
        <SnippetText dangerouslySetInnerHTML={{ __html: snippet.text }} />
        <SnippetMetadata>
          <StarRating rating={snippet.rating} />・<span>{snippet.author}</span>
          ・<span>{snippet.source}</span>
        </SnippetMetadata>
      </SnippetBox>
    </SnippetRow>
  );
};

AttributeSnippet.propTypes = {
  text: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  source: PropTypes.string.isRequired,
};

export default AttributeSnippet;
