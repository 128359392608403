export const HIGHLY_RECOMMENDED = 2;
export const RECOMMENDED = 1;
export const LIMITED_DATA = 0;
export const NOT_RECOMMENDED = -1;
export const NOT_SUPPORTED = -2;

export const QUESTION_TYPE = {
  PriceQuestion: 'PriceQuestion',
  PriceQuestionFollowup: 'PriceQuestionFollowup',
  GuideInterstitial: 'GuideInterstitial',
  AttributeQuestionFull: 'AttributeQuestionFull',
  AttributeQuestion: 'AttributeQuestion',
  TextQuestion: 'TextQuestion',
  YesNoQuestion: 'YesNoQuestion',
  ConfirmationQuestion: 'ConfirmationQuestion',
  UserInputQuestion: 'UserInputQuestion',
  AttributeChoiceQuestion: 'AttributeChoiceQuestion',
};

export const QUESTIONS_COUNTED = [
  QUESTION_TYPE.AttributeQuestion,
  QUESTION_TYPE.AttributeQuestionFull,
  QUESTION_TYPE.ConfirmationQuestion,
  QUESTION_TYPE.AttributeChoiceQuestion,
];

export const isAttributeQuestion = (question) => {
  // GuideInterstitial does not have answer.question
  if (!question) return false;
  return QUESTIONS_COUNTED.includes(question.questionType);
};

export const getAttributeCount = (questions) => {
  return questions.filter((q) => isAttributeQuestion(q)).length;
};

export const getSelectedAttributeCount = (answers) => {
  return answers.filter(
    (a) => isAttributeQuestion(a.question) && a.response !== 'not'
  ).length;
};
