import React, { Fragment } from 'react';

import {
  DetailSectionContainer,
  DetailSectionTitle,
  DetailSectionSubtitle,
  DetailSectionBody,
  BottomBorder,
} from './styles';

const DetailSection = ({ title, subtitle, children, hideBorder }) => {
  const Border = hideBorder ? Fragment : BottomBorder;

  return (
    <DetailSectionContainer>
      <Border>
        <DetailSectionTitle> {title} </DetailSectionTitle>
        <DetailSectionSubtitle> {subtitle} </DetailSectionSubtitle>
      </Border>
      <DetailSectionBody>{children}</DetailSectionBody>
    </DetailSectionContainer>
  );
};

export default DetailSection;
